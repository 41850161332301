import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index'

Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		component: Layout,
		children: [
			{
				path: '',
				name: 'Home',
				// component: () => import('@/views/home/Home')
				component: () => import('@/views/about/About')
			},
			{
				path: '/about',
				name: 'About',
				component: () => import('@/views/about/About')
			},
			{
				path: '/products', // 所有产品页面
				name: 'Products',
				component: () => import('@/views/products/Products')
			},
			{
				path: '/product', // 单个产品页面详情
				name: 'Product',
				component: () => import('@/views/product/Product')
			},
			{
				path: '/solutions', // 解决方案
				name: 'Solutions',
				component: () => import('@/views/solutions/Solutions')
			},
			{
				path: '/contact',
				name: 'Contact',
				component: () => import('@/views/contact/Contact')
			},
		]
	},
	{
		path: '*',
		name: '404',
		component: () => import('@/views/error/404')
	}
]

const router = new VueRouter({
	mode: 'history',
	scrollBehavior: () => ({ y: 0 }),
	base: process.env.BASE_URL,
	routes
})

export default router
