const log = (...msg) => {
	console && console.log(...msg)
}
class AniController {
	constructor(router) {
		this.data = {}
		this.router = router
	}

	getCurrentPath() {
		return this.router.currentRoute.path
	}

	// 新增动画控制器和容器
	add(path, selector, tl) {
		if (!this.data[path]) {
			this.data[path] = []
		}
		if (this.data[path].find(item => item.selector === selector)) {
			return
		}
		this.data[path].push({
			selector,
			tl
		})
	}

	// 删除动画
	del(path, selector) {
		const arr = this.data[path]
		if (!Array.isArray(arr)) {
			return
		}
		if (!selector) {
			this.data[path] = []
			return
		}
		const index = arr.findIndex(i => i.selector === selector)
		if (index > -1) {
			arr.splice(index, 1)
		}
	}

	_delEL(arr) {
		if (!Array.isArray(arr)) {
			return
		}
		arr.forEach(item => {
			item.el = null
		})
	}

	_addEL(arr) {
		if (!Array.isArray(arr)) {
			return
		}
		arr.forEach(item => {
			item.el = document.querySelector(item.selector)
		})
	}

	// 路由变更 -- 释放上一路由对应动画的元素  当前路由元素选中
	afterRouterChange(to, from) {
		setTimeout(() => {
			this._move()
		}, 0)
	}

	_move() {
		const currentPath = this.getCurrentPath()
		const arr = this.data[currentPath]
		if (Array.isArray(arr)) {
			for (const item of arr) {
				this.exec(item, top)
			}
		}
	}

	_play(item) {
		const { tl, active } = item
		if (!active) {
			// 当前是激活状态
			tl.play(0)
			item.active = true
		}
	}

	_reset(item) {
		const { tl, active } = item
		if (active) {
			// 当前是激活状态
			item.active = false
			tl.progress(0)
		}
	}

	exec(item) {
		if (!item.el) {
			item.el = document.querySelector(item.selector)
		}
		const { top, bottom } = item.el.getBoundingClientRect()
		const clientHeight = document.documentElement.clientHeight
		if (top >= clientHeight || bottom <= 0) {
			// 当前休眠
			this._reset(item)
		} else {
			// 当前激活动画
			this._play(item)
		}
	}

	// 页面滚动
	tick() {
		this._move()
	}

	// 页面缩放
	resize() {
		this._move()
	}
}

export default AniController
