<template>
<div class="cc-products g-position-r">
	<!-- <img class="g-position-a g-bottom-0 g-right-0 g-left-0 g-top-0" src="@/assets/img//header/bg.jpg"/> -->
	<div class="cc-products-content g-position-r">
		<div class="g-marginb-30 g-fonts-18 g-letters-22 g-fontw-600">小微云工厂</div>
		<div class="g-color-light g-letters-52 g-marginb-18">信贷产品云</div>
		<div class="clearfix cc-products-link g-marginb-10">
			<Link :fix-width="'265px'" @click="to(item.id)"  gray v-for="item in list1" :key="item.id">{{item.name}}</Link>
		</div>
		<div class="g-color-light g-letters-52 g-marginb-18">运营产品云</div>
		<div class="clearfix cc-products-link g-marginb-10">
			<Link :fix-width="'265px'" @click="to(item.id)" gray v-for="item in list2" :key="item.id">{{item.name}}</Link>
		</div>
		<div class="g-color-light g-letters-52 g-marginb-18">组件与数据服务</div>
		<div class="clearfix cc-products-link g-marginb-10">
			<Link :fix-width="'265px'" @click="to(item.id)" gray v-for="item in list3" :key="item.id">{{item.name}}</Link>
		</div>

		<div class="cc-products-divider"></div>

		<div class="g-marginb-20 g-margint-30 g-fonts-18 g-letters-22 g-fontw-600">产业数字化</div>
		<div class="clearfix cc-products-link g-marginb-10">
			<div class="g-display-ib g-marginb-20" :style="{width:'265px'}"   v-for="item in list4" :key="item.id">{{item.name}}</div>
		</div>

		<!-- <div class="cc-products-divider"></div>

		<div class="g-marginb-30 g-margint-30 g-fonts-18 g-letters-22 g-fontw-600">智慧农贸</div>
		<div class="g-color-light g-letters-52 g-marginb-18">平台产品</div>
		<div class="clearfix cc-products-link g-marginb-10">
			<Link :fix-width="'265px'" @click="to(item.id)" gray v-for="item in list5" :key="item.id">{{item.name}}</Link>
		</div>
		<div class="g-color-light g-letters-52 g-marginb-18">硬件产品</div>
		<div class="clearfix cc-products-link g-marginb-10">
			<Link :fix-width="'265px'" @click="to(item.id, item.id)" gray v-for="item in list6" :key="item.id">{{item.name}}</Link>
		</div> -->
	</div>
</div>
</template>
<script>
import { cloudFactory, digitize, intelligenceAgro } from '@/views/products/config'
export default {
	data() {
		return {
			list1: cloudFactory.loan,
			list2: cloudFactory.operation,
			list3: cloudFactory.service,
			list4: digitize,
			list5: intelligenceAgro.platforms,
			list6: intelligenceAgro.hardware,
		}
	},
	methods: {
		to(id, tid) {
			this.$emit('click-item')
			const target = `/product?p=${id}`
			const { fullPath } = this.$route
			if (fullPath === target) {
				const selector = '#p-product-p-' + tid
				this.$scrollToEl(selector)
				return
			}
			this.$router.push(target).then(() => {
				if (tid) {
					const selector = '#p-product-p-' + tid
					setTimeout(() => {
						this.$scrollToEl(selector)
					}, 1000)
				}
			})
		},
	}
}
</script>
<style lang="less" scoped>
.cc-products {
	max-width: 1300px;
	margin: 0 auto;
	background: url("~@/assets/img//header/bg.jpg") no-repeat;
	background-size: 100% 100%;
}
.cc-products-content {
	box-shadow: 0 2px 22px 0 rgba(0,0,0,0.10);
	border-radius: 2px;
	padding: 30px 40px;
}
.cc-products-link {
	margin-right: -130px;
}
.cc-products-divider {
	height: 1px;
	background-color: #E4E7ED;
}
</style>
