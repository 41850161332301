<template>
<div>
	<Wrapper :bg-img="bg" min-height="380px" id="p-solutions-p1-ani1">
		<div style="height: 93px"/>
		<div class="p-solutions-p1-ani1 g-color-white g-letters-58 g-marginb-18" style="font-size: 48px" >小微金融数字化解决方案</div>
		<div class="p-solutions-p1-ani1 g-color-white g-fonts-18 g-letters-22 g-marginb-30">以标准化平台建设能力，精准服务于小微金融全流程场景</div>
		<div class="p-solutions-p1-ani1 ">
			<a-button @click="handleApply" class="g-letters-17 g-margint-30" type="primary">
				<span>申请试用</span><a-icon class="g-fonts-12 g-verticala-m" type="right" />
			</a-button>
		</div>
	</Wrapper>
	<Wrapper min-height="auto">
		<div style="height: 70px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">服务生态</DecoratedTitle>
		<div class="g-fongs-16 g-letters-58 g-texta-c g-color-gray g-lineh-30 " style="max-width: 782px; margin: 0 auto;">
			洲联数科致力于打造多层次、多样性的金融科技产品与服务体系，为小微企业提供金融科技服务支持
		</div>
		<div style="height: 30px"/>
		<div style="max-width: 1100px; margin: 0 auto">
			<img class="g-img-auto" src="@/assets/img/products/p/solution-data-img1.png"/>
		</div>
		<div style="height: 70px"/>
	</Wrapper>
	<Wrapper min-height="480px" bg="#FAFBFE" id="p-solutions-p1-ani2">
		<div style="height: 80px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">方案优势</DecoratedTitle>
		<div style="height: 50px"/>
		<div class="g-justify">
			<div class="g-display-ib p-solutions-p1-ani2 " style="width: 240px">
				<div class="g-texta-c">
					<img style="width: 40px; height: 40px" class="g-marginb-26" src="@/assets/img/solutions/solution-finance-advantage1.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-texta-c g-fonts-16">场景经验沉淀</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">多年金融场景经验沉淀，提供专业数字化服务</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p1-ani2 " style="width: 240px">
				<div class="g-texta-c">
					<img style="width: 40px; height: 40px" class="g-marginb-26" src="@/assets/img/solutions/solution-finance-advantage2.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-texta-c g-fonts-16">优质流量匹配</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">将客户流量进行风险分层，为企业精准匹配优质流量</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p1-ani2 " style="width: 240px">
				<div class="g-texta-c">
					<img style="width: 40px; height: 40px" class="g-marginb-26" src="@/assets/img/solutions/solution-finance-advantage3.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-texta-c g-fonts-16">数据智能驱动</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">融合应用大数据与人工智能等技术，以科技创新驱动业务发展</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p1-ani2 " style="width: 240px">
				<div class="g-texta-c">
					<img style="width: 40px; height: 40px" class="g-marginb-26" src="@/assets/img/solutions/solution-finance-advantage4.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-texta-c g-fonts-16">全场景营销获客</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">线上、线下全场景高效营销获客，营销团队遍布全国21个重点城市</div>
			</div>
		</div>
	</Wrapper>
	<Wrapper min-height="707px" id="p-solutions-p1-ani3">
		<div style="height: 80px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">产品介绍</DecoratedTitle>
		<div style="height: 80px"/>
		<a-row :gutter="125">
			<a-col :span="12">
				<div class="p-home-h4-r">
					<img
						style="height: 90px;max-width: 100%; margin-bottom: -37px"
						src="@/assets/img/common/FinancialDigitalize.svg"
						alt="Industry Digitalizat">
					<DecoratedTitle >
						<span class="p-home-b4-title g-fonts-24">小微金融数字化</span>
					</DecoratedTitle>
					<div class="p-home-b4-subtitle g-color-light g-letters-52 g-margint-16 ">提供金融产品设计与标准化平台产品服务</div>
					<div class="g-letters-52 g-margint-28 g-marginb-20">热门产品</div>
					<div class="clearfix">
						<Link class="p-solutions-p1-ani3" to="/product?p=o0">智能营销平台</Link>
						<Link class="p-solutions-p1-ani3" >智能导流平台</Link>
						<Link class="p-solutions-p1-ani3" to="/product?p=l1">智能风险管理</Link>
						<Link class="p-solutions-p1-ani3" to="/product?p=l2">智能催收平台</Link>
						<Link class="p-solutions-p1-ani3" to="/product?p=s3">大数据平台</Link>
					</div>
					<div class="g-letters-52 g-margint-8 g-marginb-20">核心服务</div>
					<div class="clearfix">
						<Link class="p-solutions-p1-ani3" to="/product?p=o3">交易撮合</Link>
						<Link class="p-solutions-p1-ani3" to="/product?p=o2">统一支付</Link>
						<Link class="p-solutions-p1-ani3" to="/product?p=s2">大数据征信平台</Link>
					</div>
				</div>
			</a-col>
			<a-col :span="12">
				<img style="box-shadow: 0 2px 22px 0 rgba(0,0,0,0.08);" class="g-img-auto" src="@/assets/img/solutions/solution-finance-img2.png"/>
			</a-col>
		</a-row>
	</Wrapper>
	<Wrapper min-height="551px" :bg-img="bg2" id="p-solutions-p1-ani4">
		<div style="height: 80px"/>
		<DecoratedTitle center class="p-solutions-page1-t g-fonts-24 g-color-white g-marginb-30 g-fontw-800">合作模式</DecoratedTitle>
		<div style="height: 20px"/>
		<div class="g-justify">
			<div class="g-color-white g-display-ib p-solutions-p1-ani4" style="width: 230px">
				<div style="margin-bottom: -20px">
					<img style="height: 60px; width: auto" src="@/assets/img/common/011.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-fonts-16">渠道获客，精准匹配优质流量</div>
				<div class="g-lineh-24 g-opacity-08 letters-142">可为企业提供引流客群或对接金融机构的资金，获得撮合服务收入。针对金融机构不同场景下的痛点需求，实时匹配高转化潜能的客群流量；</div>
			</div>&nbsp;
			<div class="g-color-white g-display-ib p-solutions-p1-ani4" style="width: 230px; margin-top: 75px">
				<div style="margin-bottom: -20px">
					<img style="height: 60px; width: auto" src="@/assets/img/common/022.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-fonts-16">与机构联合定制贷款产品</div>
				<div class="g-lineh-24 g-opacity-08 letters-142">对接不同服务商合作，与机构共同定制金融产品设计后输出，整合优势以达共赢；</div>
			</div>&nbsp;
			<div class="g-color-white g-display-ib p-solutions-p1-ani4" style="width: 230px">
				<div style="margin-bottom: -20px">
					<img style="height: 60px; width: auto" src="@/assets/img/common/033.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-fonts-16">科技输出，提供SAAS云服务</div>
				<div class="g-lineh-24 g-opacity-08 letters-142">可向企业提供含系统、模型、软硬件、运维等全套标准化产品服务，按月获得产品与运营服务收入。也可根据平台交易量的约定比例进行分润，以获得技术服务收入；</div>
			</div>&nbsp;
			<div class="g-color-white g-display-ib p-solutions-p1-ani4" style="width: 230px; margin-top: 20px">
				<div style="margin-bottom: -20px">
					<img style="height: 60px; width: auto" src="@/assets/img/common/044.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-fonts-16">提供标准化API接口服务</div>
				<div class="g-lineh-24 g-opacity-08 letters-142">提供开放平台的模式对接，针对差异化场景服务提供不同的标准化API接口服务；</div>
			</div>

		</div>
	</Wrapper>
</div>
</template>
<script>
import { gsap } from 'gsap'
export default {
	name: 'Page_1',
	data() {
		return {
			bg: require('@/assets/img/solutions/solution-finance-banner.jpg'),
			bg2: require('@/assets/img/solutions/solution-finance-img3.png'),
		}
	},
	mounted() {
		this.initAni1()
		this.$move(this.$route.path, '#p-solutions-p1-ani1', this.t1)
		this.$move(this.$route.path, '#p-solutions-p1-ani2', this.t2)
		this.$move(this.$route.path, '#p-solutions-p1-ani3', this.t3)
		this.$move(this.$route.path, '#p-solutions-p1-ani4', this.t4)
	},
	beforeDestroy() {
		this.t1 = null
		this.t2 = null
		this.t3 = null
		this.t4 = null

		this.$remove(this.$route.path)
	},
	methods: {
		initAni1() {
			this.t1 = gsap.timeline({ paused: true })
			this.t1.from('.p-solutions-p1-ani1', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
			this.t2 = gsap.timeline({ paused: true })
			this.t2.from('.p-solutions-p1-ani2', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
			this.t3 = gsap.timeline({ paused: true })
			this.t3.from('.p-solutions-p1-ani3', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
			this.t4 = gsap.timeline({ paused: true })
			this.t4.from('.p-solutions-p1-ani4', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
		},
		handleApply() {
			this.$router.push({
				path: '/contact',
				query: {
					t: '小微金融数字化解决方案'
				}
			})
		}
	}
}
</script>
<style lang="less" scoped>
.letters-142 {
	letter-spacing: 1.42px;
}
</style>
<style lang="less">
.p-solutions-page1-t {
	.c-decorated-title-l > div{
		background-color: #fff!important;
	}
}
</style>
