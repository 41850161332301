import Vue from 'vue'
import DecoratedTitle from './DecoratedTitle'
import Link from './Link'
import Blank from './Blank'
import Wrapper from './Wrapper'

Vue.component('DecoratedTitle', DecoratedTitle)
Vue.component('Link', Link)
Vue.component('Blank', Blank)
Vue.component('Wrapper', Wrapper)
