<template>
<div class="c-decorated-title" :class="{'g-texta-c': center}">
	<div class="g-fontw-600">
		<slot/>
	</div>
	<div class="c-decorated-title-l" :style="{marginTop: gap}">
		<div class="c-decorated-title-l g-display-ib"></div>
	</div>
</div>
</template>
<script>
export default {
	props: {
		center: {
			type: Boolean,
			default: false
		},
		gap: {
			type: String,
			default: '10px'
		}
	}
}
</script>
<style lang="less" scoped>
@import "@/assets/styles/variables.less";
.c-decorated-title {
	line-height: 1.1;
}
.c-decorated-title-l {
	font-size: 0;
	line-height: 0;
	>div {
		width: 24px;
		height: 2px;
		background-color: @primary-color;
	}
}
</style>
