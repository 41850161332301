
export const cloudFactory = {
	// 信贷产品云
	loan: [
		{ id: 'l0', name: '信贷管理系统', icon: require('@/assets/img/products/product01-xindai.svg') },
		{ id: 'l1', name: '智能风控管理', icon: require('@/assets/img/products/product02-zhinengfengkong.svg') },
		{ id: 'l2', name: '智能催收平台', icon: require('@/assets/img/products/product03-zhinengcuishou.svg') },
		{ id: 'l3', name: '反欺诈系统', icon: require('@/assets/img/products/product04-ABS.svg') },
		{ id: 'l4', name: '产品中心', icon: require('@/assets/img/products/product05-chanpin.svg') },
		{ id: 'l5', name: '额度中心', icon: require('@/assets/img/products/product06-edu.svg') },
	],
	// 运营产品云
	operation: [
		{ id: 'o0', name: '智能营销平台', icon: require('@/assets/img/products/product07-zhinengyingxiao.svg') },
		{ id: 'o1', name: '智能CRM', icon: require('@/assets/img/products/product08-CRM.svg') },
		{ id: 'o2', name: '统一支付平台', icon: require('@/assets/img/products/product09-tongyi.svg') },
		{ id: 'o3', name: '交易撮合平台', icon: require('@/assets/img/products/product10-jiaoyi.svg') },
		{ id: 'o4', name: '短信管理平台', icon: require('@/assets/img/products/product11-duanxin.svg') },
		{ id: 'o5', name: '权限中心', icon: require('@/assets/img/products/product12-quanxian.svg') },
	],
	// 组件与数据服务
	service: [
		{ id: 's0', name: '决策引擎', icon: require('@/assets/img/products/product13-juece.svg') },
		{ id: 's1', name: '分案引擎', icon: require('@/assets/img/products/product14-fenan.svg') },
		{ id: 's2', name: '大数据征信平台', icon: require('@/assets/img/products/product15-dashuju.svg') },
		{ id: 's3', name: '大数据一体化平台', icon: require('@/assets/img/products/product16-NDW.svg') },
		{ id: 's4', name: '电子合同存证系统', icon: require('@/assets/img/products/product17-dianzi.svg') }
	]
}

// 产业数字化
export const digitize = [
	{ id: 'd0', name: '定制化产品', icon: require('@/assets/img/products/product18-dingzhihua.svg') },
	{ id: 'd1', name: '咨询方案', icon: require('@/assets/img/products/product19-zixun.svg') },
	{ id: 'd2', name: '行业研究报告', icon: require('@/assets/img/products/product20-hangye.svg') },
]

// 智慧农贸
export const intelligenceAgro = {
	platforms: [
		{ id: 'p0', name: '智慧农贸综合管理平台', icon: require('@/assets/img/products/product21-zhihui.svg') },
		{ id: 'p1', name: '农贸智慧监管平台', icon: require('@/assets/img/products/product22-nongmaozhihui.svg') },
		// { id: 'p2', name: '商户服务平台' },
		{ id: 'p3', name: '农贸大数据云平台', icon: require('@/assets/img/products/product23-nongmapdashuju.svg') },
		{ id: 'p4', name: '农贸电商平台', icon: require('@/assets/img/products/product24-nongmaodianshang.svg') },
		{ id: 'p5', name: '助农金融服务平台', icon: require('@/assets/img/products/product25-zhunong.svg') },
	],
	hardware: [
		{ id: 'h0', name: '按键溯源电子秤', icon: require('@/assets/img/products/product26-anjian.svg') },
		{ id: 'h1', name: '触摸屏溯源电子秤', icon: require('@/assets/img/products/product27-chumo.svg') },
		{ id: 'h2', name: '市场数据大屏', icon: require('@/assets/img/products/product28-shichang.svg') },
		{ id: 'h3', name: '商户信用公示屏', icon: require('@/assets/img/products/product29-shanghu.svg') },
		{ id: 'h4', name: '查询一体机', icon: require('@/assets/img/products/product30-chaxun.svg') },
		{ id: 'h5', name: '食品安全检测仪', icon: require('@/assets/img/products/product31-shipin.svg') },
		{ id: 'h6', name: '客流仪', icon: require('@/assets/img/products/product32-keliuyi.svg') },
	]
}

export const details = {
	l0: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '信贷管理系统',
		subtitle: '信贷全流程一站式智能管理',
		desc: '信贷管理系统针对信贷全流程场景提供管理服务，灵活的配置能力可覆盖多种业务场景，助力企业有效提高授信效率。',
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-xindai1.svg'),
				title: '全场景覆盖',
				desc: '覆盖线上、线下、O2O等多种业务场景，一站式解决方案输出，无需二次开发，提供高效、便捷、专业的信贷管理服务。'
			},
			{
				icon: require('@/assets/img/products/detail/service-xindai2.svg'),
				title: '强大调度能力',
				desc: '内置流程引擎，灵活调用决策引擎、标签系统、三方数据源等内外部服务，快速响应业务需求。'
			},
			{
				icon: require('@/assets/img/products/detail/service-xindai3.svg'),
				title: '灵活配置',
				desc: '支持字段级数据权限配置，页面元素、流程节点等均可针对不同的业务进行定制化，强力支撑业务拓展与快速变化。'
			},
		],
		architecture: require('@/assets/img/products/p/3.jpg'),
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '审批工作台',
				desc: '审批工作台整合汇总各类型审批任务，根据用户角色适配不同功能模块与数据展示范围，审批人员可查看个人的工作进度、待办事项、基础数据统计等。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '多渠道进件',
				desc: '用户可通过APP/PAD/H5/微信公众号等多个渠道申请进件，智能识别如OCR识别等可有效减少客户信息录入步骤，人脸识别认证可助力企业把控授信安全。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '可视化审批流配置',
				desc: '拖拽式工作流引擎可大大简化审批流程的配置，降低操作门槛，页面更加直观高效，有助于用户快速读取流程与节点信息。',
			},
			{
				icon: require('@/assets/img/common/04.svg'),
				title: '数据权限管控',
				desc: '提供包括字段、页签、页面、流程、节点等级别的配置能力，支持场景管理及版本管理。',
			},
		],
	},
	l1: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '智能风控管理',
		subtitle: '大数据赋能风险建模，助力企业高效管控风险',
		desc: '智能风控管理平台基于机器学习算法与大数据等技术，为企业提供智能化的实时风控管理解决方案。通过对业务全流程的数据监控分析，精准捕获细分场景下的各类风险信号，并进行预警告警。',
		advantages: [
			{
				icon: require('@/assets/img/products/product-advantage1.svg'),
				title: '全流程风险监控',
				desc: '覆盖贷前、贷中和贷后的全业务流程风控服务，并遵从风险识别、评估、监测和控制的风险管理流程，助力企业管控多种风险。'
			},
			{
				icon: require('@/assets/img/products/product-advantage2.svg'),
				title: '灵活配置规则',
				desc: '自主可配的决策引擎包含不同的数据组合、风控规则、评分模型等，个性化配置支持不同场景下的风险管理。'
			},
			{
				icon: require('@/assets/img/products/product-advantage3.svg'),
				title: '大数据助力风控升级',
				desc: '集成海量内外部数据源，一站式实时调用，结合多维度数据全面评估主体信用风险，同时深挖关联图谱，构建用户全面风险画像。'
			},
		],
		architecture: require('@/assets/img/products/p/4.jpg'),
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '决策引擎',
				desc: '基于海量内外部数据构建智能决策引擎，用户可在页面拖拽配置决策流，并通过决策仿真与机器学习技术动态优化决策模型，高效识别管控风险。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '智能风险模型',
				desc: '通过行业大数据、人行征信数据、经营数据、存量历史数据等进行风险策略与风险模型的建设。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '风险大盘',
				desc: '风险大盘总览当前业务场景下的风险数据统计，根据角色权限对管理人员和审批人员开放不同范围数据的看板内容，数据可视化展示简洁明了。',
			},
			{
				icon: require('@/assets/img/common/04.svg'),
				title: '监控预警',
				desc: '支持用户自定义设置智能风险预警规则，当风险信号触发规则时系统根据风险对象分类、级别或任意配置参数内部预警通知用户。',
			},
		]
	},
	l2: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '智能催收平台',
		subtitle: '助力机构智能化管控逾期账务的完整生命周期',
		desc: '面向债务催收场景提供智能化、数据化的催收云平台服务。系统可对逾期账务的完整生命周期进行管理，以智能化服务提高催收质量与效率。',
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-zhinengcuishou1.svg'),
				title: '智能匹配催收策略',
				desc: '基于海量历史数据分析，沉淀千余种催收策略。支持新案导入自动分类，智能匹配最佳催收策略。'
			},
			{
				icon: require('@/assets/img/products/detail/service-zhinengcuishou2.svg'),
				title: 'AI智能催收外呼',
				desc: '支持标准话术和个性化话术配置，AI智能外呼大大降低人力投入，提升催收效率。'
			},
			{
				icon: require('@/assets/img/products/detail/service-zhinengcuishou3.svg'),
				title: '全程监控预警',
				desc: '多维度监控案件回收、催收信息等关键指标，全程监控逾期风险并进行告警预警。'
			},
		],
		architecture: require('@/assets/img/products/p/13.jpg'),
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '智能分案',
				desc: '系统支持多种灵活的分案方式，可按户数、金额、产品、逾期天数等条件进行分配。用户可多维度配置分案策略规则，实现智能化分案处理，助力企业有效提高催收效率。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '催收决策流',
				desc: '在不同催收阶段配置相应策略，初期设置预提醒降低逾期发生率，前期催收灵活应用AI与人工介入，以最大效率催收回款。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '贷后五级分类',
				desc: '信贷资产质量定期审查，并将结果分析归类，对客户贷款进行高效便捷的分类管理。基于贷后检查生成贷款用途检查报告，监控贷款状态，记录逾期、利息、欠息等信息。',
			},
			{
				icon: require('@/assets/img/common/04.svg'),
				title: 'AI外呼',
				desc: '机器学习模型智能训练优化话术，结合知识库服务可有效提醒客户还款，并对客户还款意愿自动标记便于后续案件跟进。',
			},
		]
	},
	l3: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '反欺诈系统',
		subtitle: '精准定位拦截不同场景下的欺诈风险',
		desc: '基于海量内外部场景数据，构建智能反欺诈模型，实时捕获欺诈痕迹。融合机器学习等技术动态训练优化反欺诈规则，不断提高欺诈案件有效识别率，助力企业实时管控欺诈风险。',
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-zhinengfanqizha1.svg'),
				title: '丰富经验沉淀',
				desc: '沉淀多年小微金融行业经验，洲联在支付、票税、信用等众多金融场景下拥有丰富的欺诈管理技术和风险模型构建经验。'
			},
			{
				icon: require('@/assets/img/products/detail/service-zhinengfanqizha2.svg'),
				title: '多维度风险识别',
				desc: '支持多个信息检测维度，场景数据覆盖银行、证券、保险等多结构金融领域，实时识别监控在进件、支付、借贷、理财等业务环节面临的欺诈风险。'
			},
			{
				icon: require('@/assets/img/products/detail/service-zhinengfanqizha3.svg'),
				title: '快速及时响应',
				desc: '服务毫秒级响应，每秒超过万级并发，支持动态扩容。全天候黑产情报实时捕捉，并极速更新安全策略。'
			},
		],
		architecture: require('@/assets/img/products/p/6.jpg'),
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '反欺诈引擎',
				desc: '引擎支持多种场景化反欺诈规则与模型构建，助力企业精准识别虚假信息、冒用身份等多种欺诈风险。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '全流程监控预警',
				desc: '对于业务流程的多个环节进行欺诈风险监控，敏锐捕捉跨平台逾期、用户异动等风险，并根据优先级进行预警告警。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '策略优化',
				desc: '实时更新欺诈信息库，深挖数据价值，基于机器学习模型对欺诈策略进行自动学习与优化建议。',
			},
			{
				icon: require('@/assets/img/common/04.svg'),
				title: '智慧关联图谱',
				desc: '通过汇集多维信息，深入分析数据关联关系，可视化展示潜在关联风险。',
			},
		]
	},
	l4: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '产品中心',
		subtitle: '一站式智能化产品管理服务中心',
		desc: '产品中心实现对产品的统一化、标准化管理，规范产品建设流程，打造一站式智能化产品管理服务中心。系统支持对信贷金融产品进行标准化分类定义，对不同产品的基础属性、借款模式、收款方案等具体产品费率参数进行管理与配置。',
		architecture: require('@/assets/img/products/p/14.jpg'),
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '产品信息管理',
				desc: '标准化统一配置管理产品信息，满足各系统在信贷全流程中对全量产品信息的实时查询，实现产品信息的统一管理与输出。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '差异化产品配置',
				desc: '支持产品参数的个性化配置管理，降低了产品定制的开发成本，使得产品管理更加灵活、敏捷、高效，强力支撑业务更新迭代。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '产品配置快捷创建',
				desc: '以标准化产品配置为基础模板，可一键复制新增，批量配置管理产品信息，大大提升用户操作效率与交互体验。',
			}
		],

	},
	l5: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '额度中心',
		subtitle: '全场景额度信息统一管控',
		desc: '额度中心可对客户主体在企业内全场景的额度信息进行统一管控，额度管理可灵活补足授信风险偏差，提升用户体验，助力企业更高效的管控风险。',
		architecture: require('@/assets/img/products/p/15.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-edu1.svg'),
				title: '多层级额度管理',
				desc: '通过搭建层级完善的额度账户体系，面向个人与企业等不同客户主体，实现对公司级、客户级和产品级等多层额度管理。'
			},
			{
				icon: require('@/assets/img/products/detail/service-edu2.svg'),
				title: '统一实时管控',
				desc: '实现信贷业务全场景额度信息实时计算与统一管控，灵活补足风险偏差。'
			},
			{
				icon: require('@/assets/img/products/detail/service-edu3.svg'),
				title: '限额规则灵活配置',
				desc: '支持产品、资金、资产等多维度下的限额规则可视化灵活配置，满足不同场景下的差异化风险管控需求。'
			},
		],
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '额度账户管理',
				desc: '以标准化额度账户体系创建客户主体账户，对额度信息进行统一规范管理。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '可视化限额配置',
				desc: '可在页面对资金方、资产方或其他自定义的限额规则进行配置，实现多维度规则灵活配置。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '额度实时计算与输出',
				desc: '实现企业全场景下的额度信息实时传输、计算与调用，额度管理准确、实时、安全。',
			},
			{
				icon: require('@/assets/img/common/04.svg'),
				title: '额度管控',
				desc: '可支持风控决策和人工发起调额、降额、冻结等动作，通过相关权限审批流程后实现对客户的额度管控。',
			},
		],

	},
	o0: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '智能营销平台',
		subtitle: '打造全链路、智能化的营销服务',
		desc: '智能营销系统为企业提供多元化场景营销服务，助力企业全方位提升存量客户激活、产品交叉营销等能力。',
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-zhinengyingxiao1.svg'),
				title: '全链路营销服务',
				desc: '系统支持覆盖事前策划、事中配置及调优、事后智能复盘的全链路营销管理，提升金融机构整体营销效率。'
			},
			{
				icon: require('@/assets/img/products/detail/service-zhinengyingxiao2.svg'),
				title: '智能客群分析',
				desc: '融合大数据分析与机器学习模型构建智能分级模型，对用户行为进行精细分类打标，构建用户的信息全貌画像。'
			},
			{
				icon: require('@/assets/img/products/detail/service-zhinengyingxiao3.svg'),
				title: '实时运营分析',
				desc: '实时营销数据看板及营销比对让营销活动效果一目了然，全流程营销风险实时监控预警，准确、及时捕获营销风险。'
			},
		],
		architecture: require('@/assets/img/products/p/2.jpg'),
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '标签服务',
				desc: '整合企业内外部各类场景数据，构建用户静态标签和动态标签体系，精准打标用户行为，实现智能高效的客群分类。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '客群分析模型',
				desc: '通过机器学习、推荐算法等常用算法对用户行为标签深入加工，计算出各类细分场景量化评分以对用户分级分类。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '客户画像报告',
				desc: '基于客群画像模型与价值模型，对客户进行未来的趋势分析、价值评价，构建用户的信息全貌画像。',
			},
			{
				icon: require('@/assets/img/common/04.svg'),
				title: '实时营销看板',
				desc: '展示不同业务场景下的各类营销数据实时统计，营销效果一目了然。',
			},
		]
	},
	o1: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '智能CRM',
		subtitle: '数据智能驱动客户价值高效转化',
		desc: '智能CRM平台可助力企业高效分层管理客户，从商机线索到销售成单全流程管理，有效增强内部协作，驱动客户转化与销售增长。',
		architecture: require('@/assets/img/products/p/11.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-CRM1.svg'),
				title: '客户线索高效追踪',
				desc: '多渠道客户线索全面记录、统一管理，精细化客户标签，智能PUSH客户跟进与待办任务，实现客户高效追踪。'
			},
			{
				icon: require('@/assets/img/products/detail/service-CRM2.svg'),
				title: '流量精准转化',
				desc: '基于内外部多维度客户数据分析，实现客户线索的自动流转与用户画像精准呈现，深度洞察客户需求，从商机到合同全流程推进客户高效转化。'
			},
			{
				icon: require('@/assets/img/products/detail/service-CRM3.svg'),
				title: '大数据驱动决策',
				desc: '支持自定义规则配置，深度分析业绩目标数据、销售数据、人员行为数据、客户行为数据等，更加科学、高效地驱动企业决策优化。 '
			},
		],
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '精细化客户分级',
				desc: '通过多渠道、多埋点的数据收集，构建客户价值模型，基于数据对线索进行评分分级，分层输出不同价值的用户。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '标准化营销管理',
				desc: '对营销全流程进行拆分与标准化管控，支持从客户信息、客户关系、客户数据分析等维度对客户价值转化效率进行管理。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '多渠道触达',
				desc: '支持通过短信、邮件、AI外呼、应用消息等渠道，主动触达客户。',
			},
		],

	},

	o2: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '统一支付平台',
		subtitle: '汇聚丰富支付通道，提供标准化统一支付产品',
		desc: '统一支付平台通过构建底层账户体系提供统一支付能力，为客户方解决互联网+应用的全流程闭环，包括聚合支付、场景接入、支付数据、支付运营四大服务体系，强力支撑客户在不同场景下高效服务商家及消费者等。',
		architecture: require('@/assets/img/products/p/12.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-tongyizhifu1.svg'),
				title: '强劲的统一支付能力',
				desc: '可实现对支付交易进行统一管控，服务技术接口规范完备，支持市场上所有主流支付方式，服务能力强劲。'
			},
			{
				icon: require('@/assets/img/products/detail/service-tongyizhifu2.svg'),
				title: '完善的支付管理运营',
				desc: '支持支付全流程运营管控，从交易订单管理、交易规则管理到对账清算结算、对账差错处理等，高效、优质运营，提升用户支付体验。'
			},
			{
				icon: require('@/assets/img/products/detail/service-tongyizhifu3.svg'),
				title: '安全的支付服务',
				desc: '按渠道进行安全鉴权管理，保障支付安全，为客户提供安心可靠的交易环境。'
			},
		],
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '账户管理',
				desc: '登录鉴权、电子钱包、银行认证、收银台、二维码服务、第三方支付授权、免密授权等账户侧服务能力。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '支付运营',
				desc: '提供支付的订单管理、异常管理、账单管理、支付路由等支付业务管理服务。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '支付开放能力',
				desc: '对外可提供支付能力开放，主要包括支付渠道服务、用户授权、服务授权、支付SDK、二维码服务等能力。',
			},
			{
				icon: require('@/assets/img/common/04.svg'),
				title: '统一支付网关',
				desc: '第三方应用可通过调用统一支付网关进行支付操作，监控支付过程及结果。',
			},
		],

	},
	o3: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '交易撮合平台',
		subtitle: '快速、精准、智能匹配融资需求',
		desc: '交易撮合平台可实时加工数据,快速捕获各类融资需求，对资金资产投融资进行匹配撮合，同时可引流不同的客群，对接多种金融机构的资金。',
		architecture: require('@/assets/img/products/p/7.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-jiaoyicuohe1.svg'),
				title: '丰富场景流量',
				desc: '依托集团生态下的多种场景服务，可为企业匹配来自三方支付场景、票税平台场景、SaaS厂商场景、头部互金厂商场景等的优质客群。'
			},
			{
				icon: require('@/assets/img/products/detail/service-jiaoyicuohe2.svg'),
				title: '智能风险分层',
				desc: '对黑名单、欺诈风险、信用风险等规则对客群进行风险截拦，并通过风险建模对流量进行分层，为机构匹配最佳客群组合。'
			},
			{
				icon: require('@/assets/img/products/detail/service-jiaoyicuohe3.svg'),
				title: '实时需求监控',
				desc: '实时加工数据快速捕获各类融资需求，对资金资产投融资进行匹配撮合、实时预警、数据统计等管控。'
			},
		],
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '精准匹配优质流量',
				desc: '对客户数据进行整合分类，形成精准场景标签与用户画像。根据场景需求对客群进行分类与筛选，为不同痛点需求实时匹配高转化潜能的优质流量。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '智能资金路由',
				desc: '实时监控各方需求，触发资金路由规则，根据各资金方的准入规则、资金成本、保证金限额等条件进行筛选匹配，高效、智能地匹配撮合资金。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '风险赋能',
				desc: '可支持全流程下的交易风险实时监控、分析与预警，确保交易撮合服务安全有效展开。支持额度定价建议、高风险信息推送、策略入场支持等风险赋能服务。',
			},
		],

	},
	o4: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '短信管理平台',
		subtitle: '提供一站式短信管理服务',
		desc: '短信管理平台可提供短信验证码、系统通知、活动通知等集合多种服务，满足企业各类基础活动与运营活动的业务展开。短信路由可根据触达率规则、地域规则或其他规则确定最优话务线路，短信通道灵活配置切换。同时系统支持用户配置短信模板、自定义签名与短信发生状态通知，助力企业以最小成本达到信息触达用户最优效果。',
		architecture: require('@/assets/img/products/p/18.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-duanxin1.svg'),
				title: '短信海量并发',
				desc: '支持一次性提交上万手机号，批量快速发送'
			},
			{
				icon: require('@/assets/img/products/detail/service-duanxin2.svg'),
				title: '自定义模板配置',
				desc: '短信内容模板支持自定义配置，满足企业不同的业务诉求'
			},
			{
				icon: require('@/assets/img/products/detail/service-duanxin3.svg'),
				title: '可视化短信统计',
				desc: '可视化统计展示短信数据，短信触达情况一目了然'
			},
		],

	},
	o5: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '权限中心',
		subtitle: '助力企业统一管控系统权限',
		desc: '权限中心可实现企业内部系统权限的统一管控和用户统一登录，面向不同机构可独立化输出标准化产品，支持机构的费用结算、数据隔离以及个性化界面设置等功能。',
		architecture: require('@/assets/img/products/p/16.jpg'),
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '标准化资源权限',
				desc: '通过搭建层级完善的权限管理体系，实现企业内部多个系统的资源权限标准化管控，资源颗粒可精细到字段，大大降低权限功能的重复开发成本。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '用户统一管理',
				desc: '实时监控各方需求，触发资金路由规则，根据各资金方的准入规则、资金成本、保证金限额等条件进行筛选匹配，高效、智能地匹配撮合资金。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '个性化界面管理',
				desc: '系统支持界面风格根据各企业审美偏好进行个性化配置。',
			},
		]
	},
	s0: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '决策引擎',
		subtitle: '灵活、高效定义风险策略',
		desc: '洲联沉淀丰富风控经验，基于洲联云端数据库和企业自有数据构建智能决策引擎。用户可在页面拖拽配置决策流，并通过决策仿真与机器学习动态优化决策模型，灵活配置的决策引擎支持不同场景下的个性化风险管控。',
		architecture: require('@/assets/img/products/p/5.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-juece1.svg'),
				title: '支持多种风险模型',
				desc: '可实现评分卡、评分表、规则集等多种风险模型的构建，助力企业贴合业务场景定义更加专业、高效的风险决策。'
			},
			{
				icon: require('@/assets/img/products/detail/service-juece2.svg'),
				title: '策略可视化配置',
				desc: '支持从决策流、规则、模型到内外部数据、系统参数，均支持在页面上进行灵活配置，无需代码加工，大大降低了决策定义门槛与人力成本。'
			},
			{
				icon: require('@/assets/img/products/detail/service-juece3.svg'),
				title: '性能出众',
				desc: '引擎性能表现优异，标准配置下TPS可达到5000笔/秒，整体耗时控制在1秒以内，可满足急剧增长或日益复杂的业务需求。'
			},
		],
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '风险因子管理',
				desc: '通过页面拖拽配置即可完成业务变量、指标、规则创建，且可提供标准化的规则与模型，用户只需简单修改阈值即可使用。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '外部数据集成',
				desc: '提供外部数据的统一集成管理，页面化配置即可实现接口对接、报文解析、指标加工计算等功能。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '决策流配置',
				desc: '支持分流、回测等决策流模式，提供多种计算公式组件进行变量加工处理，实现决策流程灵活配置。',
			},
		],

	},
	s1: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '分案引擎',
		subtitle: '提供智能化分案策略引擎',
		desc: '分案引擎面向催收分案场景提供智能化、数据化的产品服务。分案核心是资源的最优化，分案引擎通过数据模型可匹配催收员与案件参数、催收策略与模型，实现资源最大效能，助力企业有效提高催收效率。',
		architecture: require('@/assets/img/products/p/17.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-fenan1.svg'),
				title: '灵活化配置',
				desc: '各层级模块支持自定义配置，可实现最小维度的精细化分案。'
			},
			{
				icon: require('@/assets/img/products/detail/service-fenan2.svg'),
				title: '多维度策略',
				desc: '覆盖逾期天数、账龄、业务类型、城市/地域、委案金额等数十个维度，可为客户的不同需求配置多种策略。'
			},
			{
				icon: require('@/assets/img/products/detail/service-fenan3.svg'),
				title: '全场景覆盖',
				desc: '覆盖停催、内催、诉讼、仲裁、委外等业务场景，也可自定义配置场景规则'
			},
			{
				icon: require('@/assets/img/products/detail/service-fenan4.svg'),
				title: '可扩展性强',
				desc: '整体框架结构划分明确，独立性强，便于后续维护和扩展开发'
			},
		],
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '策略配置',
				desc: '面向不同场景配置相应的分案策略，基于模拟数据的效果分析可动态调整优化策略，最大程度提升案件处理效能。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '维度灵活定义',
				desc: '平台提供数十种分案维度，满足用户的常见分案逻辑，同时支持自定义维度参数逻辑，以灵活适配不同场景下的个性化需求。',
			},
		],
	},
	s2: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '大数据征信平台',
		subtitle: '打造征信数据的标准集成接入服务',
		desc: '大数据征信平台汇总整合多维度、多场景下的征信数据，为企业提供统一接入管理服务，同时深挖数据关联，满足多行业的信用评估服务和风险控制场景的需求。',
		architecture: require('@/assets/img/products/p/20.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-dashujuzhengxin1.svg'),
				title: '统一标准的接口服务管理',
				desc: '可对企业内外部的多种类型接口进行标准化统一管理，支持可视化查询接口使用情况，并可全局监控系统异常。'
			},
			{
				icon: require('@/assets/img/products/detail/service-dashujuzhengxin2.svg'),
				title: '便捷可视化的接入方式',
				desc: '支持接口的可视化接入，无需额外定制化代码开发，降低接入门槛和人力成本投入，有效提高服务效率。'
			},
			{
				icon: require('@/assets/img/products/detail/service-dashujuzhengxin3.svg'),
				title: '灵活兼容的计费统计',
				desc: '平台可对不同合作模式下的付费接口进行服务计费统计，计费逻辑覆盖市场主流数据接口付费模式，成本分布一目了然。'
			},
		],
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '接口管理',
				desc: '对机构内各系统接口、机构外接数据源接口、机构外各系统接口做统一管理及可视化接入，并支持查询各接口使用、计费与异常情况。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '运营管理',
				desc: '对企业接入的外部数据源进行计费配置、服务统计等多方位的综合运营管理，支持可视化实时报表展示与数据导出。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '系统管理',
				desc: '受理请求后对相关接口进行路由分发、地址管理等逻辑处理工作。',
			},
		],

	},
	s3: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '大数据一体化平台',
		subtitle: '助力企业一站式管控数据资产',
		desc: '数据一体化工作平台提供数据集成、数据研发、数据治理和数据服务等全方位的产品服务，以all-in-one的方式构建一站式数据平台，为企业提供全链路智能大数据开发和治理服务，助力企业统一管控数据资产。',
		architecture: require('@/assets/img/products/p/21.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-NDW1.svg'),
				title: '一站式服务集成',
				desc: '平台以一站式数据研发集成方式提供服务，有效降低了大数据使用门槛，使得研发效能更加聚焦数据业务价值。'
			},
			{
				icon: require('@/assets/img/products/detail/service-NDW2.svg'),
				title: '毫秒级响应',
				desc: '提供多种查询引擎，千万级数据量查询达到毫秒级响应。'
			},
			{
				icon: require('@/assets/img/products/detail/service-NDW3.svg'),
				title: '数据安全管控',
				desc: '可实现数据权限字段级控制，支持一键数据脱敏，有效保障数据安全。'
			},
		],
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '数据地图',
				desc: '以数据搜索为基础，提供表使用说明、数据类目、数据血缘、字段血缘等工具，支持热度排序、搜索排名等功能，帮助用户简便有效管理数据。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '数据集成',
				desc: '支持数据批量/增量同步，可覆盖表、文件、FTP、接口、消息队列等多种类型数据与数据库的同步。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '数据开发',
				desc: '提供数据查询、开发脚本管理、数据血缘分析、开发编辑器、数据分析等功能，可实现混编工作流与沉浸式开发。',
			},
			{
				icon: require('@/assets/img/common/04.svg'),
				title: '任务调度',
				desc: '满足任务定时调度、依赖调度、手动调度、手动恢复等多种需求，支持不同任务类型，并可实现集群HA。',
			},
		],

	},
	s4: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '电子合同存证系统',
		subtitle: '合同签约电子化与数字存证服务',
		desc: '提供电子化签署方案及数字存证服务，记录电子合同签约全流程数据、形成完整证据链，同步存证法院、公证处等司法机构，在签约线上化的同时保障合同的司法效力。',
		architecture: require('@/assets/img/products/p/19.jpg'),
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '数据存证',
				desc: '通过接口对接，可对数据文件进行摘要并进行加密处理，同步至云服务器和公证处本地服务器进行数据存证。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '存证证书',
				desc: '平台对存证记录发放存证证书，说明电子数据的权属、生成时间、数据原始有效性，并展示存证编号。可对各类业务出具存证报告，对业务过程电子数据的真实性、完整性进行说明。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '公证保全',
				desc: '通过对数据文件增加时间戳和可信数字签名，确保文件真实记录、未经篡改。存证的文件相关数据信息都会永久保存，用户可在未来任何时间提出申请开具可信的公证书用于维权。',
			},
		],

	},
	d0: {
		title: '定制化产品',
		desc: '提供面向不同产业的更场景化、定制化产品解决方案',
	},
	d1: {
		title: '咨询方案',
		desc: '深入调研企业数字化服务建设现状，提供未来升级发展的咨询方案',
	},
	d2: {
		title: '行业研究报告',
		desc: '实时追踪细分领域动态分析研究未来趋势与发展方向',
	},
	p0: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '智慧农贸综合管理平台',
		subtitle: '智慧化管理农贸市场运营',
		desc: '洲联融合大数据与物联网等技术，为企业提供 “信息公示、产品溯源、价格检测、联网监督、智能监控” 的农贸市场综合信息化管理服务，通过权限管理与数据隔离，连接市场管理方与各层级部门协同办公，提升市场管理能力。',
		architecture: require('@/assets/img/products/p/22.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-nongmaozonghe1.svg'),
				title: '多源、安全、实时的数据采集',
				desc: ''
			},
			{
				icon: require('@/assets/img/products/detail/service-nongmaozonghe2.svg'),
				title: '驱动业务数字化，赋能各应用场景',
				desc: ''
			},
			{
				icon: require('@/assets/img/products/detail/service-nongmaozonghe3.svg'),
				title: '兼容多环境，支持多种部署方式',
				desc: ''
			},
		],
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '市场与商户管理',
				desc: '支持对各区域管辖范围内的新型农贸市场进行统一管控，并对市场内的各个商户基本信息与信用评分进行配置管理。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '溯源检测上报',
				desc: '对售卖菜品进行溯源信息管理，跟踪处理异常数据，定期进行检测上报。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '在线招商拓展',
				desc: '从市场勘察、立项审批到市场摊位发布、审核，再到商户竞拍、支付、签约，平台可实现招商拓展全流程的线上化管理。',
			},
			{
				icon: require('@/assets/img/common/04.svg'),
				title: '物业管理',
				desc: '可自动生成市场下属各商户的应收物业费用，录入实收物业费用，支持多类型数据文件的批量导入，可视化展示物业数据报表。',
			},
		],
	},
	p1: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '农贸智慧监管平台',
		subtitle: '提供全链路食品安全追溯监管服务',
		desc: '洲联数科以实现“政府推动、部门监管”的食品安全社会共治为目标，构建集企业经营管理、食品安全管理、市场局食品安全监管、公众查询等服务于一体的食品安全追溯监管云平台。实现政府食品安全监管来源可溯、去向可查、风险可控、责任可究。',
		architecture: require('@/assets/img/products/p/23.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-nongmaojianguan1.svg'),
				title: '网格管理',
				desc: ''
			},
			{
				icon: require('@/assets/img/products/detail/service-nongmaojianguan2.svg'),
				title: '数据共享',
				desc: ''
			},
			{
				icon: require('@/assets/img/products/detail/service-nongmaojianguan3.svg'),
				title: '精准追溯',
				desc: ''
			},
			{
				icon: require('@/assets/img/products/detail/service-nongmaojianguan4.svg'),
				title: '开放API',
				desc: ''
			},
		],
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '溯源查询',
				desc: '对各区域下属市场所有售卖菜品的溯源信息进行整合与统一管理。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '数据上报管理',
				desc: '受理各市场定期上报的溯源与经营数据，并对异常信息进行追踪监管。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '市场评分',
				desc: '基于多维度数据汇总，构建评分模型对各市场进行综合评分与优化建议。',
			},
			{
				icon: require('@/assets/img/common/04.svg'),
				title: '实时交易预警',
				desc: '监控各市场交易全流程，触发预警规则后实时预警告警相关人员。',
			},
		],
	},
	p3: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '农贸大数据云平台',
		subtitle: '数据智能驱动农贸智慧升级',
		desc: '农贸大数据云平台汇总市场运营数据，深度分析数据指标，结合商户生命周期管理进行数据建模与智能分析。以大数据驱动商户营销与市场运营优化，助力商户提升经营关键指标。同时可视化呈现各类统计数据，强力支撑政府相关部门纳统等工作。',
		architecture: require('@/assets/img/products/p/24.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-dashuju1.svg'),
				title: '多源、安全、实时的数据采集',
				desc: ''
			},
			{
				icon: require('@/assets/img/products/detail/service-dashuju2.svg'),
				title: '驱动业务数字化，赋能场景应用',
				desc: ''
			},
			{
				icon: require('@/assets/img/products/detail/service-dashuju3.svg'),
				title: '兼容多环境，支持多种部署方式',
				desc: ''
			},
		],
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '洲联指数分',
				desc: '洲联指数可深度解析数据后展示农产品价格趋势指数、交易量趋势分析指数、饮食结构变化分析指数等各类关联变化趋势，有效辅助市场交易决策。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '用户画像',
				desc: '基于多维度内外部数据整合分析，对商户、消费者、经营者等多方用户的行为数据等深入分析打标，提供全方位用户画像。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '异常预警',
				desc: '支持自定义预警规则，对交易异常、价格异常等多场景数据异常风险进行统一标准管控。',
			},
		],
	},
	p4: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '农贸电商平台',
		subtitle: '打造农贸市场电商模式',
		desc: '平台支持市场管理方、商户及消费者通过APP或小程序进行在线下单、支付、极速配送，提供多种便捷销售渠道入口，实现农贸市场+社区配送，打通农贸市场电商模式，助力传统农贸智慧升级。',
		architecture: require('@/assets/img/products/p/25.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-nongmaodianshang1.svg'),
				title: '多渠道销售入口',
				desc: ''
			},
			{
				icon: require('@/assets/img/products/detail/service-nongmaodianshang2.svg'),
				title: '丰富的场景营销活动',
				desc: ''
			},
			{
				icon: require('@/assets/img/products/detail/service-nongmaodianshang3.svg'),
				title: '高效拉新与拓客',
				desc: ''
			},
		],
		functions: [
			{
				icon: require('@/assets/img/common/01.svg'),
				title: '多渠道入口',
				desc: '支持多方用户通过APP、小程序、H5及PC端等多渠道入口登录使用。',
			},
			{
				icon: require('@/assets/img/common/02.svg'),
				title: '用户营销',
				desc: '支持多种营销活动的管理与配置，并可对会员体系进行精细化运营管理，加强消费者对农贸市场的黏性。',
			},
			{
				icon: require('@/assets/img/common/03.svg'),
				title: '运营管理',
				desc: '对商品发布、交易订单、商户信息、分销等进行运营管理。',
			},
		],
	},
	p5: {
		bg: require('@/assets/img/products/product-banner2.png'),
		title: '助农金融服务平台',
		subtitle: '面向农贸场景提供一站式综合金融服务',
		desc: '助农金融平台面向农贸产业链各方、终端用户、农户等主体，提供汇集融资、消费、投资的一站式综合金融服务。',
		architecture: require('@/assets/img/products/p/26.jpg'),
		advantages: [
			{
				icon: require('@/assets/img/products/detail/service-zhunongjinrong1.svg'),
				title: '多年金融服务经验沉淀',
				desc: ''
			},
			{
				icon: require('@/assets/img/products/detail/service-zhunongjinrong2.svg'),
				title: '贴合业务场景融资需求',
				desc: ''
			},
			{
				icon: require('@/assets/img/products/detail/service-zhunongjinrong3.svg'),
				title: '一站式产业链金融服务',
				desc: ''
			},
		],
	},
	h: {
		title: '硬件产品介绍',
		subtitle: 'HARDWARE PRODUCT INTRODUCTION',
		desc: '通过安装应用不同类型的智慧农贸硬件产品，提升农贸市场运作效率的同时，还可采集菜品溯源、客流监控、消费者反馈等多维度数据，以此构建智慧物联网，助力传统农贸数字化升级转型。',
		pics: [
			{
				icon: require('@/assets/img/products/p/hardware1.jpg'),
				id: 'h0'
			},
			{
				icon: require('@/assets/img/products/p/hardware2.png'),
				id: 'h1'
			},
			{
				icon: require('@/assets/img/products/p/hardware3.jpg'),
				id: 'h2'
			},
			{
				icon: require('@/assets/img/products/p/hardware4.png'),
				id: 'h3'
			},
			{
				icon: require('@/assets/img/products/p/hardware5.jpg'),
				id: 'h4'
			},
			{
				icon: require('@/assets/img/products/p/hardware6.png'),
				id: 'h5'
			},
			{
				icon: require('@/assets/img/products/p/hardware7.jpg'),
				id: 'h6'
			},
		]
	},
	h0: {

	},
	h1: {

	},
	h2: {

	},
	h3: {

	},
	h4: {

	},
	h5: {

	},
	h6: {

	},
}

export const productDetails = {
	l0: {
		title: '信贷管理系统',
		desc: '高效便捷的智能化线上信贷审批，大大提高审批效率',

	},
	l1: {
		title: '大数据风控',
		desc: '基于海量数据构建风险策略，精准管控全流程场景下的各类风险',

	},
	l2: {
		title: '智能催收平台',
		desc: '对逾期账务的完整生命周期进行管理，智能化服务提高催收质量',

	},
	l3: {
		title: '智能反欺诈',
		desc: '智能模型实时定位捕获欺诈痕迹，助力企业精准识别欺诈风险',

	},
	l4: {
		title: '产品中心',
		desc: '标准化产品建设流程，打造一站式智能化产品管理服务中心',

	},
	l5: {
		title: '额度中心',
		desc: '实现对企业全场景额度信息的统一管控，灵活补足授信风险偏差',

	},
	o0: {
		title: '智能营销系统',
		desc: '为企业提供多元化场景营销服务，全方位提升促活、拉新等能力',

	},
	o1: {
		title: '智能CRM',
		desc: '细分客户特征后标准化统一管理，促进客户高效转化与精准营销',

	},

	o2: {
		title: '统一支付平台介绍',
		desc: '基于底层丰富稳定的支付通道，为企业提供标准化统一支付产品',

	},
	o3: {
		title: '交易撮合平台',
		desc: '实时加工数据，快速捕获各类融资需求，对资金资产投融资进行匹配撮合。',

	},
	o4: {
		title: '短信管理平台',
		desc: '提供短信验证码、活动通知等服务，满足企业各类基础活动与运营活动的业务展开',

	},
	o5: {
		title: '权限中心',
		desc: '实现企业内部系统权限的统一管控，面向不同机构可独立输出标准化产品',

	},
	s0: {
		title: '决策引擎',
		desc: '基于洲联云端数据库和企业自有数据，支持不同场景下的个性化风险管控',

	},
	s1: {
		title: '分案引擎',
		desc: '支持多维度配置分案策略规则，实现智能化分案处理',

	},
	s2: {
		title: '大数据征信平台',
		desc: '汇总整合多维度、多场景下的征信数据，满足多行业的信用评估服务需求',

	},
	s3: {
		title: '大数据平台',
		desc: '以all-in-one的方式构建一站式数据平台，助力企业统一管控数据资产',

	},
	s4: {
		title: '电子合同存证系统',
		desc: '提供电子化签署方案及数字存证服务，同步存证法院、公证处等司法机构',
	},
	d0: {
		title: '定制化产品',
		desc: '提供面向不同产业的更场景化、定制化产品解决方案',
	},
	d1: {
		title: '咨询方案',
		desc: '深入调研企业数字化服务建设现状，提供未来升级发展的咨询方案',
	},
	d2: {
		title: '行业研究报告',
		desc: '实时追踪细分领域动态分析研究未来趋势与发展方向',
	},
	p0: {
		title: '农贸综合管理平台',
		desc: '提供 “信息公示、产品溯源、价格检测、联网监督、智能监控” 的综合信息化管理服务',

	},
	p1: {
		title: '农贸监管管理平台',
		desc: '汇集经营管理、食品安全监管、公众查询等服务的食品安全追溯监管云平台',

	},
	p3: {
		title: '农贸大数据平台',
		desc: '以大数据驱动商户营销与市场运营优化，强力支撑政府相关部门纳统等工作',

	},
	p4: {
		title: '农贸电商平台',
		desc: '提供多种便捷销售渠道入口，实现农贸市场+社区配送，打通农贸市场电商模式',
	},
	p5: {
		title: '助农金融服务平台',
		desc: '面向农贸产业链各方主体，提供融资、消费、投资等一站式综合金融服务',
	},
	h0: {
		title: '按键溯源电子秤',
		desc: '提供称重、收银、食品溯源、数据采集、广告播放等功能'
	},
	h1: {
		title: '触摸屏溯源电子秤',
		desc: '支持AI菜品识别、称重、收银、食品溯源、数据采集等'
	},
	h2: {
		title: '市场数据大屏',
		desc: '实时展示市场交易、客流、商户等经营数据与商品检测信息'
	},
	h3: {
		title: '商户信用公示屏',
		desc: '展示商户证照、信用、交易明细、支付二维码等信息'
	},
	h4: {
		title: '查询一体机',
		desc: '自动查询市场、会员、商品价格、食品溯源与检测等信息'
	},
	h5: {
		title: '食品安全检测仪',
		desc: '快速检测商品中的农药残毒、添加剂、违禁品'
	},
	h6: {
		title: '客流仪',
		desc: '统计通道口出入人数、驻留时长等数据，并提供人群流动轨迹等信息'
	},
}
