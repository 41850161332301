<template>
<div class="c-wrapper" :style="style">
	<div class="c-wrapper-inner" :style="{'max-width': max}">
		<slot/>
	</div>
</div>
</template>
<script>
export default {
	name: 'WrapperComponent',
	props: {
		max: {
			type: String,
			default: '1280px'
		},
		minHeight: {
			type: String,
			default: '750px'
		},
		bg: {
			type: String,
			default: 'transparent'
		},
		bgImg: {
			type: String,
			default: ''
		},
	},
	computed: {
		style() {
			const ret = {
				'min-height': this.minHeight, 'background-color': this.bg
			}
			if (this.bgImg) {
				ret.backgroundImage = `url(${this.bgImg})`
				ret.backgroundRepeat = 'no-repeat'
				ret.backgroundSize = 'cover'
			}
			return ret
		}
	}
}
</script>
<style lang="less" scoped>
.c-wrapper {

}
.c-wrapper-inner {
	margin: 0 auto;
}
</style>
