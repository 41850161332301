export const config = [
	{
		img: require('@/assets/img/header/solution-25d1.png'),
		text: '小微金融数字化解决方案',
		query: '1'
	},
	{
		img: require('@/assets/img/header/solution-25d2.png'),
		text: '跨境电商云生态解决方案',
		query: '2'
	},
	// {
	// 	img: require('@/assets/img/header/solution-25d3.png'),
	// 	text: '智慧农贸解决方案',
	// 	query: '3'
	// },
]

const context = require.context('./pages')
export const components = context.keys().map(context).reduce((ret, item) => {
	ret[item.default.name] = item.default
	return ret
}, {})

