import axios from 'axios'
export const BASE_URL = '' // 测试环境
const WEB_ROOT = ''
// create an axios instance
const service = axios.create({
	baseURL: BASE_URL + WEB_ROOT, // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 20000, // request timeout
})

// request interceptor
service.interceptors.request.use(
	config => {
		return config
	},
	error => {
		// do something with request error// for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	response => {
		const res = response.data
		if (res.code && res.code === '0') {
			return res
		}
		return Promise.reject(new Error(res.mesg || 'Error'))
	},
	error => {
		return Promise.reject(error)
	}
)

export default service

