<template>
<div class="cc-solutions">
	<div class="cc-solutions-content">
		<div @click="handleClick(item.query)" class="cc-solutions-item g-position-r g-display-ib g-texta-c g-cursor-p" v-for="item in list" :key="item.text">
			<img :src="item.img" :alt="item.text"/>
			<div class="g-letters-59 g-fonts-16 g-margint-6">{{item.text}}</div>
		</div>
	</div>
</div>
</template>
<script>
import { config } from '@/views/solutions/config'
export default {
	data() {
		return {
			list: config,
		}
	},
	methods: {
		handleClick(param) {
			const { path, query = {}} = this.$route
			if (path !== '/solutions' || query.p !== param) {
				this.$router.push({ path: '/solutions', query: { p: param }})
			}
		}
	}
}
</script>
<style lang="less" scoped>
.cc-solutions {
	max-width: 760px;
	margin: 0 auto;
}
.cc-solutions-content {
	background: #FFFFFF;
	box-shadow: 0 2px 22px 0 rgba(0,0,0,0.10);
	border-radius: 2px;
	padding: 40px 0px;
}
.cc-solutions-item {
	width: 46%;
	min-height: 90px;
	> img {
		width: 54px;
		height: 54px;
	}
	&+& {
		&::before {
			position: absolute;
			content: ' ';
			display: block;
			top: 0;
			left: 0;
			bottom: 0;
			border-left: 1px dashed #E4E7ED;
		}
	}
}
.cc-solutions-divider {
	height: 1px;
	background-color: #E4E7ED;
}
</style>
