<template>
	<a-layout class="g-height-100p">
		<a-layout-header class="layout-header g-position-r g-zindex-9">
			<NavBar />
		</a-layout-header>
		<a-layout-content id="global-main" class="layout-main">
			<transition name="fade" mode="out-in">
				<keep-alive :key="componentKey">
					<router-view />
				</keep-alive>
			</transition>
			<Footer />
		</a-layout-content>
	</a-layout>
</template>

<script>
import NavBar from './components/NavBar'
import Footer from './components/Footer'
export default {
	name: 'Layout',
	components: { NavBar, Footer },
	data() {
		return {
			componentKey: ''
		}
	},
	watch: {
		$route(route) {
			this.componentKey = route.path
		}
	}

}
</script>
<style>
	.layout-main {
		padding: 0;
		overflow-x: hidden;
	}
</style>
