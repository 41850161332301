<template>
<div>
	<Wrapper :bg-img="bg" min-height="380px" id="p-solutions-p2-ani1">
		<div style="height: 93px"/>
		<div class="p-solutions-p2-ani1 g-color-white g-letters-58 g-marginb-18" style="font-size: 48px" >跨境电商数字化解决方案</div>
		<div class="p-solutions-p2-ani1 g-color-white g-fonts-18 g-letters-22 g-marginb-30">汇集金融与科技能力，为跨境电商场景提供数字化服务。</div>
		<div class="p-solutions-p2-ani1 ">
			<a-button @click="handleApply" class="g-letters-17 g-margint-30" type="primary">
				<span>申请试用</span><a-icon class="g-fonts-12 g-verticala-m" type="right" />
			</a-button>
		</div>
	</Wrapper>
	<Wrapper>
		<div style="height: 70px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">服务生态</DecoratedTitle>
		<div class="g-fongs-16 g-letters-58 g-texta-c g-color-gray g-lineh-30 " style="max-width: 782px; margin: 0 auto;">
			洲联数科聚焦跨境电商场景，面向跨境电商卖家、支付机构和资金机构等多方提供一站式数字方案服务
		</div>
		<div style="height: 30px"/>
		<div style="max-width: 1100px; margin: 0 auto">
			<img class="g-img-auto" src="@/assets/img/products/p/10.jpg"/>
		</div>
		<div style="height: 70px"/>
	</Wrapper>
	<Wrapper min-height="480px" bg="#FAFBFE"  id="p-solutions-p2-ani2">
		<div style="height: 80px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">方案优势</DecoratedTitle>
		<div style="height: 50px"/>
		<div class="g-justify">
			<div class="g-display-ib p-solutions-p2-ani2 " style="width: 240px">
				<div class="g-texta-c">
					<img style="width: 40px; height: 40px" class="g-marginb-26" src="@/assets/img/solutions/solution-data-advantage1.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-texta-c g-fonts-16">安全合规</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">数字化、批量化国际收支申报，确保交易合规</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p2-ani2 " style="width: 240px">
				<div class="g-texta-c">
					<img style="width: 40px; height: 40px" class="g-marginb-26" src="@/assets/img/solutions/solution-data-advantage2.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-texta-c g-fonts-16">灵活定制</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">面向场景多方客户灵活定制方案与服务内容，支持快速接入</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p2-ani2 " style="width: 240px">
				<div class="g-texta-c">
					<img style="width: 40px; height: 40px" class="g-marginb-26" src="@/assets/img/solutions/solution-data-advantage3.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-texta-c g-fonts-16">全场景营销</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">线上、线下全场景多渠道获客，营销团队遍布全国21个重点城市</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p2-ani2 " style="width: 240px">
				<div class="g-texta-c">
					<img style="width: 40px; height: 40px" class="g-marginb-26" src="@/assets/img/solutions/solution-data-advantage4.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-texta-c g-fonts-16">智能运营</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">大数据助力运营管理，数字化服务助力企业运营管理</div>
			</div>
		</div>
	</Wrapper>
	<Wrapper min-height="707px"  id="p-solutions-p2-ani3">
		<div style="height: 80px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">产品介绍</DecoratedTitle>
		<div style="height: 80px"/>
		<a-row :gutter="125">
			<a-col :span="12">
				<div class="p-home-h4-r">
					<img
						style="height: 90px;max-width: 100%; margin-bottom: -37px"
						src="@/assets/img/common/DigitalServices.svg"
						alt="Industry Digitalizat"
						>
					<DecoratedTitle >
						<span class="p-home-b4-title g-fonts-24">跨境电商数字化服务</span>
					</DecoratedTitle>
					<!--<div class="p-home-b4-subtitle g-color-light g-letters-52 g-margint-16 ">提供行业研究分析与场景化产品打造</div>-->
					<div class="g-letters-52 g-margint-28 g-marginb-20">热门服务</div>
					<div class="clearfix">
						<div class="p-home-b4-d-item g-marginr-30 g-display-ib g-color-primary">融资服务</div>
						<!-- <div class="p-home-b4-d-item g-marginr-30 g-display-ib g-color-primary">外汇兑换</div> -->
						<div class="p-home-b4-d-item g-marginr-30 g-display-ib g-color-primary">跨境收款</div>
						<div class="p-home-b4-d-item g-marginr-30 g-display-ib g-color-primary">融资担保</div>
						<!--<Link class="p-solutions-p2-ani3 ">融资服务</Link>-->
						<!--<Link class="p-solutions-p2-ani3 ">外汇兑换</Link>-->
						<!--<Link class="p-solutions-p2-ani3 ">跨境收款</Link>-->
						<!--<Link class="p-solutions-p2-ani3 ">融资担保</Link>-->
					</div>
					<div class="g-letters-52 g-margint-28 g-marginb-20">热门产品</div>
					<div class="clearfix">
						<!-- <div class="p-home-b4-d-item g-marginr-30 g-display-ib g-color-primary">统一支付平台</div>
						<div class="p-home-b4-d-item g-marginr-30 g-display-ib g-color-primary">大数据风控平台</div>
						<div class="p-home-b4-d-item g-marginr-30 g-display-ib g-color-primary">智能营销平台</div> -->
						<Link class="p-solutions-p2-ani3 " to="/product?p=o2">统一支付平台</Link>
						<Link class="p-solutions-p2-ani3 " to="/product?p=l1">大数据风控平台</Link>
						<Link class="p-solutions-p2-ani3 " to="/product?p=o0">智能营销平台</Link>
					</div>
				</div>
			</a-col>
			<a-col :span="12">
				<img class="g-img-auto" src="@/assets/img/solutions/index-img3.png"/>
			</a-col>
		</a-row>
	</Wrapper>
	<Wrapper min-height="505px"  id="p-solutions-p2-ani4">
		<div style="height: 80px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">合作模式</DecoratedTitle>
		<div style="height: 50px"/>
		<div class="g-justify">
			<div class="g-display-ib p-solutions-p2-ani4 " style="width: 156px">
				<img class="g-img-auto" src="@/assets/img/solutions/solution-farmer-cooperation.svg"/>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p2-ani4 " style="width: 188px;">
				<div style="margin-bottom: -20px">
					<img style="height: 60px; width: auto" src="@/assets/img/common/01.svg"/>
				</div>
				<div class="g-letters-59 g-fonts-16 g-fontw-600 g-marginb-30">面向跨境电商卖家</div>
				<div class="g-lineh-24 g-opacity-08 g-color-gray letters-142">提供收单、结算、融资、融贷担保、保险等科技与金融服务</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p2-ani4 " style="width: 188px;">
				<div style="margin-bottom: -20px">
					<img style="height: 60px; width: auto" src="@/assets/img/common/02.svg"/>
				</div>
				<div class="g-letters-59 g-fonts-16 g-fontw-600 g-marginb-30">面向支付机构</div>
				<div class="g-lineh-24 g-opacity-08 g-color-gray letters-142">提供增加融资、保险产品等金融服务，并支持营销推广等场景服务；</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p2-ani4 " style="width: 188px;">
				<div style="margin-bottom: -20px">
					<img style="height: 60px; width: auto" src="@/assets/img/common/03.svg"/>
				</div>
				<div class="g-letters-59 g-fonts-16 g-fontw-600 g-marginb-30">面向资金机构</div>
				<div class="g-lineh-24 g-opacity-08 g-color-gray letters-142">提供营销推广、大数据风控模型、辅助运营等综合定制化服务；</div>
			</div>

		</div>
	</Wrapper>
	<Wrapper min-height="600px" bg="#FAFBFE"  id="p-solutions-p2-ani5">
		<div style="height: 80px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">合作客户</DecoratedTitle>
		<div style="height: 60px"/>
		<div style="margin-right: -15px" class="g-texta-c">
			<div class="p-solutions-p2-ani5  g-border-1 g-display-ib g-texta-c g-marginr-15 g-marginb-15" style="width: 200px; height: 150px;" v-for="(item, index) in partners" :key="index">
				<img style="width: 100%; height: 100%" :src="item"/>
			</div>
		</div>
		<div style="height: 80px"/>
	</Wrapper>
</div>
</template>
<script>
import { gsap } from 'gsap'
export default {
	name: 'Page_2',
	data() {
		return {
			bg: require('@/assets/img/solutions/solution-data-banner.jpg'),
			partners: [
				require('@/assets/img/solutions/solution-data-logo1.png'),
				require('@/assets/img/solutions/solution-data-logo2.png'),
				require('@/assets/img/solutions/solution-data-logo3.png'),
				require('@/assets/img/solutions/solution-data-logo4.png'),
				require('@/assets/img/solutions/solution-data-logo5.png'),
				require('@/assets/img/solutions/solution-data-logo6.png'),
				require('@/assets/img/solutions/solution-data-logo7.png'),
				require('@/assets/img/solutions/solution-data-logo8.png'),
				require('@/assets/img/solutions/solution-data-logo9.png'),
				require('@/assets/img/solutions/solution-data-logo10.png'),
				require('@/assets/img/solutions/solution-data-logo11.png'),
				require('@/assets/img/solutions/solution-data-logo12.png'),
			]
		}
	},
	mounted() {
		this.initAni1()
		this.$move(this.$route.path, '#p-solutions-p2-ani1', this.t1)
		this.$move(this.$route.path, '#p-solutions-p2-ani2', this.t2)
		this.$move(this.$route.path, '#p-solutions-p2-ani3', this.t3)
		this.$move(this.$route.path, '#p-solutions-p2-ani4', this.t4)
		this.$move(this.$route.path, '#p-solutions-p2-ani5', this.t5)
	},
	beforeDestroy() {
		this.t1 = null
		this.t2 = null
		this.t3 = null
		this.t4 = null
		this.t5 = null

		this.$remove(this.$route.path)
	},
	methods: {
		initAni1() {
			this.t1 = gsap.timeline({ paused: true })
			this.t1.from('.p-solutions-p2-ani1', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
			this.t2 = gsap.timeline({ paused: true })
			this.t2.from('.p-solutions-p2-ani2', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
			this.t3 = gsap.timeline({ paused: true })
			this.t3.from('.p-solutions-p2-ani3', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
			this.t4 = gsap.timeline({ paused: true })
			this.t4.from('.p-solutions-p2-ani4', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
			this.t5 = gsap.timeline({ paused: true })
			this.t5.from('.p-solutions-p2-ani5', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
		},
		handleApply() {
			this.$router.push({
				path: '/contact',
				query: {
					t: '跨境电商数字化解决方案'
				}
			})
		}
	}
}
</script>
<style lang="less" scoped>
.letters-142 {
	letter-spacing: 1.42px;
}
</style>
