<template>
	<a :style="style" href="javascript:;" :class="className" class="c-link g-display-ib g-cursor-p" @click="handleClick">
		<span class="g-verticala-m">
			<slot/>
		</span>
		<a-icon class="g-fonts-12 g-marginl-4" type="right" />
	</a>
</template>
<script>
export default {
	props: {
		to: {
			type: String,
			default: ''
		},
		single: {
			type: Boolean,
			default: false
		},
		gray: {
			type: Boolean,
			default: false
		},
		white: {
			type: Boolean,
			default: false
		},
		fixWidth: {
			type: String,
			default: ''
		}
	},
	computed: {
		className() {
			return {
				'c-link-margin': !this.single,
				'c-link-primary': !this.gray && !this.white,
				'c-link-gray': this.gray,
				'c-link-white': this.white,
			}
		},
		style() {
			return this.fixWidth === '' ? {} : {
				width: this.fixWidth,
				marginRight: 0
			}
		}
	},
	methods: {
		handleClick() {
			this.$emit('click')
			if (this.to) {
				this.$router.push(this.to)
			}
		}
	}
}
</script>
<style lang="less" scoped>
@import "@/assets/styles/variables.less";
.c-link {
	transition: all ease-in-out .3s;
}
.c-link-primary {
	color: @primary-color;
	&:hover {
		color: lighten(@primary-color, 20%);
	}
}
.c-link-gray {
	color: @text-color;
	&:hover {
		color: @primary-color;
	}
}
.c-link-white {
	color: #fff;
	&:hover {
		color: lighten(#fff, 20%);
	}
}
.c-link-margin{
	margin-right: 70px;
	margin-bottom: 20px;
}
</style>
