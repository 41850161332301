<template>
	<div class="layout-footer">
		<!-- <div class="layout-footer-content">
			<div class="g-paddingt-20 g-paddingb-20" />
			<a-row>
				<a-col :span="7">
					<div class="g-color-white g-letters-17 g-marginb-36">热门产品</div>
					<div class="g-color-white g-letters-17 g-cursor-p g-marginb-20 g-opacity-05" @click="handleClick('/product?p=l2')">智能催收平台</div>
					<div class="g-color-white g-letters-17 g-cursor-p g-marginb-20 g-opacity-05" @click="handleClick('/product?p=o0')">智能营销平台</div>
					<div class="g-color-white g-letters-17 g-cursor-p g-marginb-20 g-opacity-05" @click="handleClick('/product?p=s3')">大数据一体化平台</div>
					<div class="g-color-white g-letters-17 g-cursor-p g-marginb-20 g-opacity-05" @click="handleClick('/product?p=o3')">交易撮合平台</div>
				</a-col>
				<a-col :span="7">
					<div class="g-color-white g-letters-17 g-marginb-36">热门解决方案</div>
					<div class="g-color-white g-cursor-p g-letters-17 g-marginb-20 g-opacity-05" @click="handleClick('/solutions?p=1')">小微金融</div>
					<div class="g-color-white g-cursor-p g-letters-17 g-marginb-20 g-opacity-05" @click="handleClick('/solutions?p=2')">跨境电商</div>
					<div class="g-color-white g-cursor-p g-letters-17 g-marginb-20 g-opacity-05" @click="handleClick('/solutions?p=3')">智慧农贸</div>
				</a-col>
				<a-col :span="5">
					<div class="g-color-white g-letters-17 g-marginb-36">合作关系</div>
					<div
						class="g-color-white g-cursor-p g-letters-17 g-marginb-20 g-opacity-05"
						@click="handleClick('/about', '#p-about-anchor-2')">
						合作案例
					</div>
				</a-col>
				<a-col :span="5">
					<div class="layout-footer-divider g-display-ib g-opacity-01" />
					<div class="g-float-r">
						<a-button type="primary" size="small"  @click="handleContact">联系我们</a-button>
						<div class="g-color-white g-letters-17 g-marginb-15">服务时间</div>
						<div class="g-color-white  g-letters-17 g-opacity-05">每周一至周五</div>
						<div class="g-color-white  g-letters-17 g-opacity-05">09:30-18:00</div>
					</div>
				</a-col>
			</a-row>
		</div> -->
		<!-- <div class="g-paddingt-20 g-paddingb-30"/> -->
		<div class="layout-footer-1px  g-opacity-01"/>
		<div class="g-texta-c">
			<div class=" g-margint-30">
				<div class="g-display-ib g-color-white g-opacity-05 g-letters-17 g-cursor-p" @click="handleContact">联系我们</div>
			</div>
			<div class="g-color-white g-fonts-12 g-margint-20 g-letters-14 g-opacity-05">
				Copyright © 2023-2024 版权所有 <a style="color: white" href="https://beian.miit.gov.cn" target="blank">沪ICP备2023038737号-1</a>
				<!-- <div style="padding-bottom: 20px"><a href="https://xyt.xcc.cn/getpcInfo?sn=1485542951704915968&language=CN&certType=8&url=*.zliantec.com" target="_blank" style="position: relative;display: inline-block;height: 38px;">
					<div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed src="https://program.xinchacha.com/web/1485542951704915968=*.zliantec.com.svg"width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/></a></div> -->
			</div>
		</div>
	</div>
</template>
<script>
export default {
	methods: {
		handleContact() {
			if (this.$route.path !== '/contact') { this.$router.push('/contact') }
		},
		handleComplete(selector) {
			const element = document.querySelector(selector)
			if (element) {
				const { y } = element.getBoundingClientRect()
				const top = y + document.documentElement.scrollTop - 20
				this.$scrollTo({
					top: top,
					behavior: 'smooth'
				})
			}
		},
		handleClick(path, selector) {
			if (this.$route.path !== path) {
				this.$router.push(path).then(() => {
					if (selector) {
						setTimeout(() => {
							this.handleComplete(selector)
						}, 1000)
					}
				})
			} else {
				selector && this.handleComplete(selector)
			}
		},
	}
}
</script>
<style lang="less" scoped>

.layout-footer {
	// min-height: 426px;
	background-color:   #0D1726;
}
.layout-footer-content {
	max-width: 1200px;
	margin: 0 auto;
}
.layout-footer-divider {
	width: 1px;
	height: 180px;
	background: #fff;
}
.layout-footer-1px {
	height: 1px;
	background: #fff;
}
.c-opacity-15 {
	opacity: 0.15;
}
</style>
