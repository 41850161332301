<template>
<div>
	<Wrapper :bg-img="bg" min-height="380px" id="p-solutions-p3-ani1">
		<div style="height: 93px"/>
		<div class="p-solutions-p3-ani1 g-color-white g-letters-58 g-marginb-18" style="font-size: 48px" >智慧农贸解决方案</div>
		<div class="p-solutions-p3-ani1 g-color-white g-fonts-18 g-letters-22 g-marginb-30">构建智慧农贸服务生态 助力农贸数字化升级</div>
		<div class="p-solutions-p3-ani1 ">
			<a-button @click="handleApply" class="g-letters-17 g-margint-30" type="primary">
				<span>申请试用</span><a-icon class="g-fonts-12 g-verticala-m" type="right" />
			</a-button>
		</div>
	</Wrapper>
	<Wrapper min-height="923px">
		<div style="height: 70px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">服务生态</DecoratedTitle>
		<div class="g-fongs-16 g-letters-58 g-texta-c g-color-gray g-lineh-30 " style="max-width: 782px; margin: 0 auto;">
			洲联数科顺应“互联网+农贸”的趋势，打造智慧农贸服务生态向外输出。标准化智慧型农贸市场管理平台可实现信息公开、交易溯源、联网检测等功能，助力传统农贸智慧升级
		</div>
		<div style="height: 30px"/>
		<div style="max-width: 1100px; height: 623px; margin: 0 auto">
			<img class="g-img-fit" src="@/assets/img/products/p/solution-farmer-img.png"/>
		</div>
	</Wrapper>
	<Wrapper min-height="480px" bg="#FAFBFE" id="p-solutions-p3-ani2">
		<div style="height: 80px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">服务优势</DecoratedTitle>
		<div style="height: 50px"/>
		<div class="g-justify">
			<div class="g-display-ib p-solutions-p3-ani2 " style="width: 240px">
				<div class="g-texta-c">
					<img style="width: 40px; height: 40px" class="g-marginb-26" src="@/assets/img/solutions/solution-farmer-advantage1.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-texta-c g-fonts-16">智慧物联驱动</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">融合AIot智慧物联技术与大数据科学提供专业服务，以科技驱动产业场景智慧升级</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p3-ani2 " style="width: 240px">
				<div class="g-texta-c">
					<img style="width: 40px; height: 40px" class="g-marginb-26" src="@/assets/img/solutions/solution-farmer-advantage2.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-texta-c g-fonts-16">覆盖全链路场景</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">面向农贸产业链上下游提供全链路、多类型场景服务</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p3-ani2 " style="width: 240px">
				<div class="g-texta-c">
					<img style="width: 40px; height: 40px" class="g-marginb-26" src="@/assets/img/solutions/solution-farmer-advantage3.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-texta-c g-fonts-16">提供定制化服务</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">探索多元化商业模式，软硬件结合的服务适配合作方的定制需求，以达合作共赢</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p3-ani2 " style="width: 240px">
				<div class="g-texta-c">
					<img style="width: 40px; height: 40px" class="g-marginb-26" src="@/assets/img/solutions/solution-farmer-advantage4.svg"/>
				</div>
				<div class="g-letters-59 g-fontw-600 g-marginb-30 g-texta-c g-fonts-16">专业运营管理</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">专业运营团队遍布全国21个重点城市，业务+技术双轴推进，运营管理流程化、规范化</div>
			</div>
		</div>
	</Wrapper>
	<Wrapper min-height="707px" id="p-solutions-p3-ani3">
		<div style="height: 80px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">产品介绍</DecoratedTitle>
		<div style="height: 80px"/>
		<a-row :gutter="125">
			<a-col :span="12">
				<div class="p-home-h4-r">
					<img
						style="height: 90px;max-width: 100%; margin-bottom: -37px"
						src="@/assets/img/common/IntelligenceFarmers.svg"
						alt="Industry Digitalizat">
					<DecoratedTitle >
						<span class="p-home-b4-title g-fonts-24">智慧农贸</span>
					</DecoratedTitle>
					<div class="p-home-b4-subtitle g-color-light g-letters-52 g-margint-16 ">提供农贸场景全流程解决方案</div>
					<div class="g-letters-52 g-margint-28 g-marginb-20">热门产品</div>
					<div class="clearfix">
						<Link class="p-solutions-p3-ani3" to="/product?p=p1">智慧监管管理平台</Link>
						<Link class="p-solutions-p3-ani3" to="/product?p=p3">农贸大数据云平台</Link>
						<Link class="p-solutions-p3-ani3" to="/product?p=p4">农贸电商平台</Link>
						<Link class="p-solutions-p3-ani3" to="/product?p=p5">助农金融服务平台</Link>
					</div>
					<div class="g-letters-52 g-margint-8 g-marginb-20">硬件产品</div>
					<div class="clearfix">
						<Link class="p-solutions-p3-ani3" to="/product?p=h1">触屏溯源电子秤</Link>
						<Link class="p-solutions-p3-ani3" to="/product?p=h3">市场公示屏</Link>
						<Link class="p-solutions-p3-ani3" to="/product?p=h4">触控查询一体机</Link>
					</div>
				</div>
			</a-col>
			<a-col :span="12">
				<img style="box-shadow: 0 2px 22px 0 rgba(0,0,0,0.08);" class="g-img-auto" src="@/assets/img/solutions/solution-farmer-img3.png"/>
			</a-col>
		</a-row>
	</Wrapper>
	<Wrapper min-height="525px" id="p-solutions-p3-ani4">
		<div style="height: 50px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">合作模式</DecoratedTitle>
		<div style="height: 50px"/>
		<div class="g-justify">
			<div class="g-display-ib p-solutions-p3-ani4" style="width: 156px">
				<img class="g-img-auto" src="@/assets/img/solutions/solution-farmer-cooperation.svg"/>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p3-ani4" style="width: 200px;">
				<div style="margin-bottom: -20px">
					<img style="height: 60px; width: auto" src="@/assets/img/common/01.svg"/>
				</div>
				<div class="g-letters-59 g-fonts-16 g-fontw-600 g-marginb-30">科技输出，赋能新农贸</div>
				<div class="g-lineh-24 g-opacity-08 g-color-gray letters-142">为农贸市场管理方提供科技建设与金融服务，如输出农贸市场综合管理平台、智慧溯源秤等软硬件产品，同时可围绕产业上下游提供理财与贷款服务。</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p3-ani4" style="width: 200px;">
				<div style="margin-bottom: -20px">
					<img style="height: 60px; width: auto" src="@/assets/img/common/02.svg"/>
				</div>
				<div class="g-letters-59 g-fonts-16 g-fontw-600 g-marginb-30">联合经营，拓展农贸新市场</div>
				<div class="g-lineh-24 g-opacity-08 g-color-gray letters-142">与当地政府及企业合作——如达成战略合作成立合资公司，各方根据自身优势共同打造智慧农贸平台。洲联主要负责市场的信息化、系统化和智慧化方案设计、研发与用户运营，合作方主要负责日常管理等相关工作。</div>
			</div>&nbsp;
			<div class="g-display-ib p-solutions-p3-ani4" style="width: 200px;">
				<div style="margin-bottom: -20px">
					<img style="height: 60px; width: auto" src="@/assets/img/common/03.svg"/>
				</div>
				<div class="g-letters-59 g-fonts-16 g-fontw-600 g-marginb-30">独立经营，打造农贸新标杆</div>
				<div class="g-lineh-24 g-opacity-08 g-color-gray letters-142">由洲联独立负责市场智慧化建设及日常运营，全方位打造农贸标杆市场。同时与相关资源方进行部分领域的合作，如承包农贸市场物业管理等。</div>
			</div>
		</div>
		<div style="height: 50px"/>
	</Wrapper>
	<Wrapper min-height="600px" bg="#FAFBFE" id="p-solutions-p3-ani5">
		<div style="height: 80px"/>
		<DecoratedTitle center class="g-fonts-24 g-letters-29 g-marginb-30">合作案例</DecoratedTitle>
		<div style="height: 40px"/>
		<div class="clearfix">
			<div class="g-float-r pp-page3-e2 g-position-r">
				<img class="g-img-auto" src="@/assets/img/solutions/solution-farmer-img4.png"/>
				<div class="g-position-a g-color-white g-fonts-16" style="top: 150px; left: 50px; right: 50px; line-height: 33px; letter-spacing: 1.62px">
					智慧农贸项目累计合作多省市地区下的30余家市场，服务商户总数达2000余家，智慧电子秤输出已达共计约2400台，电子秤累计交易金额突破3亿元…
				</div>
			</div>
			<div class="pp-page3-e">
				<div class="pp-page3-e-inner">
					<a-row :gutter="74">
						<a-col :span="12" class="g-marginb-20 p-solutions-p3-ani5">
							<div/>
							<div style="margin-bottom: -20px">
								<img style="height: 60px; width: auto" src="@/assets/img/common/01.svg"/>
							</div>
							<div class="g-letters-59 g-fonts-16 g-fontw-600 g-marginb-30">中渼智慧农贸项目</div>
							<div style="min-height: 96px" class="g-lineh-24 g-opacity-08 g-color-gray letters-142">对接福州中渼集团，已全面接管其下辖的约16个市场，对接商务局和市场监督管理局，支持政府相关部门实时获取市场数据。</div>
						</a-col>
						<a-col :span="12" class="g-marginb-20 p-solutions-p3-ani5">
							<div/>
							<div style="margin-bottom: -20px">
								<img style="height: 60px; width: auto" src="@/assets/img/common/02.svg"/>
							</div>
							<div class="g-letters-59 g-fonts-16 g-fontw-600 g-marginb-30">盐城霆善科技项目</div>
							<div style="min-height: 96px" class="g-lineh-24 g-opacity-08 g-color-gray letters-142">已完成展厅部署，包括按键电子秤、安卓触屏电子秤、商户信息屏、市场查询机、交易大数据展示屏等硬件，为后续交易推广打下基础。</div>
						</a-col>
						<a-col :span="12" class="g-marginb-20 p-solutions-p3-ani5">
							<div/>
							<div style="margin-bottom: -20px">
								<img style="height: 60px; width: auto" src="@/assets/img/common/03.svg"/>
							</div>
							<div class="g-letters-59 g-fonts-16 g-fontw-600 g-marginb-30">农贸研究报告</div>
							<div style="min-height: 96px" class="g-lineh-24 g-opacity-08 g-color-gray letters-142">调研农贸行业，编写农贸研究报告，预发表至期刊，扩大品牌影响力与信任度。</div>
							<!--<div class="g-letters-59 g-fonts-16 g-fontw-600 g-marginb-30">郑州市投集市项目</div>-->
							<!--<div style="min-height: 96px" class="g-lineh-24 g-opacity-08 g-color-gray letters-142">致力于为农贸市场管理方提供相关科技与金融服务，主要有农贸市场综合管理平台、农产品溯源管理平台、在线招商平台、移动物管平；</div>-->
						</a-col>
						<a-col :span="12" class="g-marginb-20 p-solutions-p3-ani5">
							<!--<div/>-->
							<!--<div style="margin-bottom: -20px">-->
								<!--<img style="height: 60px; width: auto" src="@/assets/img/common/04.svg"/>-->
							<!--</div>-->
							<!--<div class="g-letters-59 g-fonts-16 g-fontw-600 g-marginb-30">农贸研究报告</div>-->
							<!--<div style="min-height: 96px" class="g-lineh-24 g-opacity-08 g-color-gray letters-142">调研农贸行业，编写农贸研究报告，预发表至期刊，扩大品牌影响力与信任度。</div>-->
						</a-col>
					</a-row>
				</div>
			</div>
		</div>
		<div style="height: 100px"/>
	</Wrapper>
</div>
</template>
<script>
import { gsap } from 'gsap'
export default {
	name: 'Page_3',
	data() {
		return {
			bg: require('@/assets/img/solutions/solution-farmer-banner.jpg'),
		}
	},
	mounted() {
		this.initAni1()
		this.$move(this.$route.path, '#p-solutions-p3-ani1', this.t1)
		this.$move(this.$route.path, '#p-solutions-p3-ani2', this.t2)
		this.$move(this.$route.path, '#p-solutions-p3-ani3', this.t3)
		this.$move(this.$route.path, '#p-solutions-p3-ani4', this.t4)
		this.$move(this.$route.path, '#p-solutions-p3-ani5', this.t5)
	},
	beforeDestroy() {
		this.t1 = null
		this.t2 = null
		this.t3 = null
		this.t4 = null
		this.t5 = null

		this.$remove(this.$route.path)
	},
	methods: {
		initAni1() {
			this.t1 = gsap.timeline({ paused: true })
			this.t1.from('.p-solutions-p3-ani1', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
			this.t2 = gsap.timeline({ paused: true })
			this.t2.from('.p-solutions-p3-ani2', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
			this.t3 = gsap.timeline({ paused: true })
			this.t3.from('.p-solutions-p3-ani3', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
			this.t4 = gsap.timeline({ paused: true })
			this.t4.from('.p-solutions-p3-ani4', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
			this.t5 = gsap.timeline({ paused: true })
			this.t5.from('.p-solutions-p3-ani5', { y: 40, duration: 0.5, ease: 'circ.out', stagger: 0.1 })
		},
		handleApply() {
			this.$router.push({
				path: '/contact',
				query: {
					t: '智能农贸解决方案'
				}
			})
		}
	}
}
</script>
<style lang="less" scoped>
.letters-142 {
	letter-spacing: 1.42px;
}
.pp-page3-e {
	margin-right: 344px;
	height: 465px;
	overflow: hidden;
	background: #fff;
}
.pp-page3-e-inner {
	margin: 30px 40px;

}
.pp-page3-e2 {
	width: 344px;
	height: 465px;
}
</style>
