import 'core-js/stable'
import 'regenerator-runtime/runtime'
// 兼容ie9响应式polyfill
import 'matchmedia-polyfill'
import 'matchmedia-polyfill/matchMedia.addListener'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/styles/reset.css' // global css
import '@/assets/styles/index.less' // global css
import '@/assets/styles/global.less' // global css

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { isObject, type, isNumber, isNil } from '@/utils/index'

import { cloudFactory, intelligenceAgro } from '@/views/products/config.js'

// 自定义组件
import '@/components'

import AniController from './AniController'

import {
	Button, message, notification, Row, Col, Layout, Space, Modal, ConfigProvider, Divider,
	Affix, Icon, Dropdown, FormModel, Input, Popover, Tabs
} from 'ant-design-vue'

import request from '@/api'

Vue.use(Tabs)
Vue.use(Popover)
Vue.use(Input)
Vue.use(FormModel)
Vue.use(Dropdown)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Affix)
Vue.use(Row)
Vue.use(Col)
Vue.use(Layout)
Vue.use(Space)
Vue.use(Modal)
Vue.use(ConfigProvider)
Vue.use(Divider)
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$destroyAll = Modal.destroyAll

Vue.prototype.$message = message
Vue.prototype.$notification = notification

NProgress.configure({ showSpinner: false })
Vue.config.productionTip = false

Vue.prototype.$type = type
Vue.prototype.$isObject = isObject
Vue.prototype.$isNumber = isNumber
Vue.prototype.$isNil = isNil

Vue.prototype.$request = request

const isIE = (() => {
	const ua = window.navigator.userAgent
	var isIE = ua.indexOf('compatible') > -1 && ua.indexOf('MSIE') > -1
	var isIE11 = ua.indexOf('Trident') > -1 && ua.indexOf('rv:11.0') > -1
	return isIE || isIE11
})()

Vue.prototype.$scrollTo = (params) => {
	const { top = 0, left = 0 } = params
	if (isIE) {
		window.scrollTo(left, top)
	} else {
		window.scrollTo(params)
	}
}

Vue.prototype.$scrollToEl = (selector) => {
	const element = document.querySelector(selector)
	if (element) {
		const { y } = element.getBoundingClientRect()
		const top = y + document.documentElement.scrollTop - 20
		Vue.prototype.$scrollTo({
			top: top,
			behavior: 'smooth'
		})
	}
}

function render() {
	const instance = new Vue({
		router,
		store,
	  	render: h => h(App),
	}).$mount('#app')

	const aniController = new AniController(router)

	router.beforeEach(async(to, from, next) => {
		const arr = [
			{ id: '1', name: '小微金融数字化解决方案' },
			{ id: '2', name: '跨境电商数字化解决方案' },
			{ id: '3', name: '智慧农贸解决方案' },
		]
		const { loan, operation, service } = cloudFactory
		const { platforms, hardware } = intelligenceAgro
		const newArr = [...loan, ...operation, ...service, ...platforms, ...hardware, ...arr]
		const { p } = to.query
		const name = '洲联数科'
		if (p !== undefined) {
			const title = newArr.filter(item => {
				if (item.id === p) {
					return item
				}
			})
			document.title = `${name}-${title[0].name}`
		} else {
			document.title = name
		}

		NProgress.start()
		return next()
	})
	router.afterEach((to, from) => {
		// finish progress bar
		NProgress.done()
		const np = document.querySelector('#nprogress')
		if (np && np.parentNode) {
			np.parentNode.removeChild(np)
		}
		aniController.afterRouterChange(to, from)
	})

	const scrollHandler = e => {
		const top = e.target.documentElement.scrollTop

		instance.$emit('scroll', top)
		aniController.tick(top)
	}
	document.addEventListener('scroll', scrollHandler, {
		passive: true
	})
	Vue.prototype.$move = (...arg) => {
		aniController.add(...arg)
	}
	Vue.prototype.$remove = (...arg) => {
		aniController.del(...arg)
	}
}
render()
