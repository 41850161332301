import { render, staticRenderFns } from "./Page_1.vue?vue&type=template&id=e9ba1364&scoped=true&"
import script from "./Page_1.vue?vue&type=script&lang=js&"
export * from "./Page_1.vue?vue&type=script&lang=js&"
import style0 from "./Page_1.vue?vue&type=style&index=0&id=e9ba1364&prod&lang=less&scoped=true&"
import style1 from "./Page_1.vue?vue&type=style&index=1&id=e9ba1364&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9ba1364",
  null
  
)

export default component.exports