<template>
<div class="c-blank" :style="style"/>
</template>
<script>
export default {
	props: {
		width: {
			type: [String, Number],
			default: 0
		},
		height: {
			type: [String, Number],
			default: 0
		},
	},
	computed: {
		style() {
			const width = this.$type(this.width) === 'number' ? `${this.width}px` : this.width
			const height = this.$type(this.height) === 'number' ? `${this.height}px` : this.height
			return { width, height }
		}
	}
}
</script>
<style lang="less" scoped>
.c-blank {

}
</style>
