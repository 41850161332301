<template>
<div class="l-nav" :class="{'l-nav-shadow': hasShadow}">
	<div class="clearfix">
		<div class="l-nav-logo g-display-ib">
			<span class="g-cursor-p" @click="handleNav">
				<img src="@/assets/img/home/index-logo.png" style="height:32px"/>
			</span>
		</div>
		<!-- <div class="l-nav-menu g-display-ib">
			<a-dropdown v-model="visible" overlayClassName="pro-menu"  :overlayStyle="{maxWidth: '1300px'}">
				<router-link class="g-display-ib" to="/products">
					<div :class="{'l-nav-link-active': isActive('/products')}" class="l-nav-link g-display-ib">
						<span>产品</span>
					</div>
				</router-link>
				<Products @click-item="handleClose" slot="overlay"/>
			</a-dropdown>
			<a-dropdown v-model="isHidden" :overlayStyle="{width: '100%'}">
				<div :class="{'l-nav-link-active': isActive('/solutions')}"  class="l-nav-link g-display-ib">
					<span>解决方案</span>
				</div>
				<Solutions @click.native="handleHidden" slot="overlay"/>
			</a-dropdown>

			<router-link class="g-display-ib" to="/about">
				<div :class="{'l-nav-link-active': isActive('/about')}"  class="l-nav-link g-display-ib">
					<span>关于我们</span>
				</div>
			</router-link>
		</div> -->
		<div class="g-float-r">
			<button class="l-nav-btn" @click="handleContact">联系我们</button>
		</div>
	</div>

</div>
</template>
<script>
import Products from './components/Products'
import Solutions from './components/Solutions'
export default {
	components: { Products, Solutions },
	data() {
		return {
			activeIndex: '/',
			visible: false,
			hasShadow: false,
			isHidden:false
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler(route) {
				this.activeIndex = route.path
			}
		}
	},
	mounted() {
		this.$root.$on('scroll', this.handleScroll)
	},
	beforeDestroy() {
		this.$root.$off('scroll', this.handleScroll)
	},
	methods: {
		handleClose() {
			this.visible = false
		},
		handleHidden(){
			this.isHidden = false;
		},
		handleScroll(top) {
			if (top > 80) {
				if (!this.hasShadow) {
					this.hasShadow = true
				}
			} else {
				if (this.hasShadow) {
					this.hasShadow = false
				}
			}
		},
		isActive(path) {
			if (path === this.activeIndex) {
				return true
			}
			if (path === '/products' && this.activeIndex === '/product') {
				return true
			}
		},
		handleSelect(key, keyPath) {
			console && console.log(key, keyPath)
		},
		handleMouseIn(item) {
			console && console.log(item)
		},
		handleMouseOut(item) {

		},
		handleContact() {
			if (this.$route.path !== '/contact') { this.$router.push('/contact') }
		},
		handleNav() {
			if (this.$route.path !== '/') { this.$router.push('/') }
		}
	}
}
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
<style>

@media screen and (max-width: 1600px) {
	.pro-menu{max-width: 1000px !important;}
}
@media screen and (max-width: 1400px) {
	.pro-menu{max-width: 850px !important;}
}
</style>
